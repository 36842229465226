import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import './CardsWrap.css'
import axios from 'axios';
import HOST_URL from '../config';
import { Oval } from 'react-loader-spinner'
import { textAlign } from '@mui/system';

export default function ParentSubCategoriesCardsWrap({ category_id, medium }) {
    // console.log("category_id")
    // console.log(category_id)
    // console.log("category_id")
    const [Data, setData] = useState([])
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        let isMounted = true;
        setLoading(true);
        if (isMounted) {
            getParentSubCategories(category_id)
        }
        // if(Array.isArray(data) && isMounted) setData(data)

        return () => {
            isMounted = false
        };
    }, [category_id])

    async function getParentSubCategories(category_id) {
        // API request
        await axios.get(`${HOST_URL}api/past-paper/parent-sub-categories/` + category_id).then(response => {
            setData(response.data);
            setLoading(false);
        })
    }

    return (

        isLoading ?
            <div className="spinner" style={{
                marginTop: "250px",
                marginBottom: "250px",
                display: "flex",
                justifyContent: "center"
            }}>
                <Oval
                    height={40}
                    width={40}
                    color="#4fa94d"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                    ariaLabel='oval-loading'
                    secondaryColor="#4fa94d"
                    strokeWidth={2}
                    strokeWidthSecondary={2}
                />
            </div>
            :
            (Data.length === 0) ?
                // return (
                <div className="spinner" style={{
                    marginTop: "250px",
                    marginBottom: "250px",
                    display: "flex",
                    justifyContent: "center",
                    fontSize: "20px"
                }}>
                    No Sub Categories
                </div>
                // )
                :
                <div className='cards-wrap'>
                    {
                        Data.map(item => {
                            {
                                const filteredPapers = item.papers.filter(paper => paper.medium_id == medium);
                                // console.log(filteredPapers)
                                if (item.children.length === 0) {
                                    return (
                                        <div key={item.id} className="card">
                                            {/* {item.parent.name} */}
                                            <Link
                                                to={{
                                                    pathname: "/subjects",
                                                    state: { sub_category_id: item.id, sub_category: item.name, category: item.category.category } // your data array of objects
                                                }}
                                            >

                                                <img src={`${HOST_URL}storage/` + item.image} alt="Past Paper" className="image" />

                                                <div className="content">
                                                    <div className="label-wrap">
                                                        {/* <Link to="" className="video-link"><p className="label curved">Video</p></Link> */}
                                                        {/* <img src="/images/video-circle.png" alt="Video Icon" className="video-icon" /> */}
                                                    </div>
                                                    <div className="sub-content">
                                                        <p className="title">{item.name}</p>

                                                        <div className="labels">
                                                            <div className="label-wrap">
                                                                <p className="label light-blue">{filteredPapers.length + " Papers"}</p>
                                                                {/* <p className="label royal-blue">Answer sheet</p> */}
                                                            </div>
                                                            <div className="label-wrap">
                                                                {/* <Link to="" className="video-link"> <p className="label purple">Paper discussion</p></Link>
                                                    <img src="/images/video-circle.png" alt="Video Icon" className="video-icon small" /> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    )
                                }
                                else {
                                    return (
                                        <div key={item.id} className="card">
                                            <Link
                                                to={{
                                                    pathname: "/sub-category",
                                                    state: { sub_category_id: item.id, sub_category: item.name }// your data array of objects
                                                }}
                                            >
                                                <img src={`${HOST_URL}storage/` + item.image} alt="Past Paper" className="image" />

                                                <div className="content">
                                                    <div className="label-wrap">
                                                        {/* <Link to="" className="video-link"><p className="label curved">Video</p></Link> */}
                                                        {/* <img src="/images/video-circle.png" alt="Video Icon" className="video-icon" /> */}
                                                    </div>

                                                    <div className="sub-content">
                                                        <p className="title">{item.name}</p>

                                                        <div className="labels">
                                                            <div className="label-wrap">
                                                                {
                                                                    (item.children.length === 0) ? <p className="label light-blue">{filteredPapers.length + " Papers"}</p> : <p className="label light-blue">{item.children.length + " Sub Categories"}</p>
                                                                }                                            </div>
                                                            <div className="label-wrap">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    )
                                }
                            }
                        })

                    }
                </div>
    )
}