// import { Home } from '@material-ui/icons';
import React from 'react';
import '../../App.css';
import HeroSection from '../HeroSection';

const Home = () => {

  return (
    <div className=''>
      <HeroSection/>
    </div>
  )
}
export default Home;

