import React, { useEffect, useState } from 'react'
// import PaperPartsCardsWrap from './CardsWrap/PaperPartsCardsWrap'
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import HOST_URL from './config';
import { Oval } from 'react-loader-spinner'

const ViewPaperPart = () => {
  const location = useLocation();
  const part = location.state.part;
  const [Data, setData] = useState('')
  const [Message, setMessage] = useState('')
  const [Subject, setSubject] = useState([])
  const [SubjectSubCategory, setSubjectSubCategory] = useState([])
  const [SubjectCategory, setSubjectCategory] = useState([])
  const history = useHistory();
  const [isLoading, setLoading] = useState(false);
  const [isButtonLoading, setButtonLoading] = useState(false);

  useEffect(() => {
    let isMounted = true;
    setLoading(true);

    let isLoggedInStatus = localStorage.getItem('isLoggedIn');
    if (isLoggedInStatus === "true") {
      if (isMounted) {
        getPaperAnswerSheet(part)
      }
    }
    else {
      history.push('/authentication-failed');
      // history.push('/login');
      // window.location.reload('/login')
    }
    // if(Array.isArray(data) && isMounted) setData(data)

    return () => {
      isMounted = false
    };
  }, [part])


  async function getPaperAnswerSheet(part) {
    const token = localStorage.getItem('token');
    // console.log(token)

    await axios.get(`${HOST_URL}api/view-part-answer-sheet/` + part, {
      headers: { 'Authorization': `Bearer ${token}` },
    }).then(response => {
      // console.log(response.data.file)
      if (response.data.expired === true && response.data.subject) {
        setMessage("Subscription Expired ...");
        setData(0);
        setSubject(response.data.subject);
        setSubjectCategory(response.data.subject.category);
        setSubjectSubCategory(response.data.subject.sub_category);
        setLoading(false)
      }
      else {
        if (response.data.subject) {
          setData(0);
          setSubject(response.data.subject);
          setSubjectCategory(response.data.subject.category);
          setSubjectSubCategory(response.data.subject.sub_category);
          setLoading(false)
        } else {
          setData(response.data.answer_sheet);
          setLoading(false)
        }
      }
    })
  }
  if (Data == 0 && Subject) {
    // console.log('sdsdsd')
    function



      handleOnPay() {
      setButtonLoading(true);
      const token = localStorage.getItem('token');

      axios.post(`${HOST_URL}api/create-order`, {
        subject_id: Subject.id
      },
        {
          headers: { 'Authorization': `Bearer ${token}` },
        })
        .then(response => {
          // console.log(response)
          // console.log(response.data)
          setButtonLoading(false);
          if (response.data == false) {
            alert("Failed to do Payment")
          }
          else {
            window.open(response.data)
          }
        })
        .catch(error => {
          setButtonLoading(false);
          console.log(error);
          alert("Failed to do Payment")
        });
    }
    return (
      isLoading ?
        <div className="spinner" style={{
          marginTop: "100px",
          marginBottom: "100px",
          display: "flex",
          justifyContent: "center"
        }}>
          <Oval
            height={40}
            width={40}
            color="#4fa94d"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel='oval-loading'
            secondaryColor="#4fa94d"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>
        :
        <div
          style={{
            width: "100%",
            height: "300px",
          }}
        >

          <div
            style={{
              // width : "1000px",
              marginTop: "200px",
              marginBottom: "350px",
              // marginBottom: "-500px",
              // bottom: "30%"
            }}
          >

            <div style={{
              // display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "200px",
              border: "3px solid green",
              textAlign: "center",
              margin: "auto",
              width: "50%",
            }}>
              {
                Message != '' ? <h2 style={{
                  // display: "flex",
                  // justifyContent: "center",
                  // alignItems: "center",
                  textAlign: "center",
                  marginBottom: "35px",
                  color: "red"
                }}> {Message} </h2> : <div style={{
                  marginBottom: "35px",
                }}></div>
              }
              <div style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                // height: "200px",
                // border: "3px solid green",
                textAlign: "center",
                // margin: "auto",
                // width: "50%",
              }}>

                <div>
                  Name :  {Subject.subject} ({SubjectCategory.category} {SubjectSubCategory.name})
                  <br></br>
                  Price :     {Subject.special_price}
                  <br></br>
                  <br></br>
                  <br></br>

                  {isButtonLoading ? (
                    <div className="spinner">
                      <Oval
                        height={30}
                        width={30}
                        color="#4fa94d"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                        ariaLabel='oval-loading'
                        secondaryColor="#4fa94d"
                        strokeWidth={2}
                        strokeWidthSecondary={2}
                      />
                    </div>
                  ) : (
                    <div>
                      <button style={{
                        backgroundColor: 'red',
                        color: 'white',
                        padding: '10px 20px',
                        borderRadius: '5px',
                        border: 'none',
                        cursor: 'pointer',
                        marginTop: '5px',
                      }} onClick={handleOnPay}>Subscribe</button>
                      {/* <button onClick={handleOnPay}>Pay</button> */}
                    </div>
                  )
                  }


                </div>
              </div>
            </div>
          </div>
        </div >
    );

  }

  else {

    return (
      isLoading ?
        <div className="spinner" style={{
          marginTop: "100px",
          marginBottom: "100px",
          display: "flex",
          justifyContent: "center"
        }}>
          <Oval
            height={40}
            width={40}
            color="#4fa94d"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel='oval-loading'
            secondaryColor="#4fa94d"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>
        :
        <div>

          {
            // console.log(Data)
            (Data) ?
              // <embed
              //   src={Data + "#toolbar=0"}
              //   type="application/pdf"
              //   width="100%"
              //   height="100%"
              //   frameBorder="0"
              //   scrolling="auto"
              // /> 
              <div
                style={{
                  width: "100%",
                  height: "1000px",
                }}
              >
                <iframe src={Data + "#toolbar=0"} title="Answer Sheet Viewer" width="100%" height="100%"></iframe>
              </div>
              // <div
              //   style={{
              //     width: "100%",
              //     height: "300px",
              //   }}
              // >

              //   <div
              //     style={{
              //       // width : "1000px",
              //       marginTop: "50px",
              //       // marginBottom: "-500px",
              //       // bottom: "30%"
              //     }}
              //   >

              //     <div style={{
              //       display: "flex",
              //       justifyContent: "center",
              //       alignItems: "center",
              //       height: "200px",
              //       border: "3px solid green",
              //       textAlign: "center",
              //       margin: "auto",
              //       width: "50%",
              //     }}>
              //       <div>
              //         <a href={Data} target="_blank" style={{
              //           fontSize: "50px"
              //         }}
              //         >View</a>
              //         {/* <h1 className="label light-blue">No File</h1> */}
              //       </div>
              //     </div>
              //   </div>
              // </div >
              :
              isLoading ?
                <div className="spinner" style={{
                  marginTop: "100px",
                  marginBottom: "100px",
                  display: "flex",
                  justifyContent: "center"
                }}>
                  <Oval
                    height={40}
                    width={40}
                    color="#4fa94d"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                    ariaLabel='oval-loading'
                    secondaryColor="#4fa94d"
                    strokeWidth={2}
                    strokeWidthSecondary={2}
                  />
                </div>
                :

                <div
                  style={{
                    width: "100%",
                    height: "300px",
                  }}
                >

                  <div
                    style={{
                      // width : "1000px",
                      marginTop: "50px",
                      // marginBottom: "-500px",
                      // bottom: "30%"
                    }}
                  >

                    <div style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "200px",
                      border: "3px solid green",
                      textAlign: "center",
                      margin: "auto",
                      width: "50%",
                    }}>
                      <div>
                        <h1 className="label light-blue">No Answer Sheet</h1>
                      </div>
                    </div>
                  </div>
                </div >
          }
        </div>
    );
  }
};

export default ViewPaperPart;
