import React from "react";
import "./DownloadApp.css";
import appss from '../app-ss.jpg';

const DownloadApp = () => {
  return (
    <section className="download-app">
      <div className="main-download-app">
        <div className="download-app-text">
          <h1>Download the app</h1>
          {/* <h2>Lorem ipsum dolor sit a met consectetur</h2> */}
          <p>
            PassPaper.lk offers a convenient and user-friendly mobile application that allows students to access a vast database of past papers, model answer sheets, and paper discussion videos on your mobile devices. The app is available for download on both Android and iOS platforms, and it offers a seamless experience for users to access and download past papers for a range of different examinations.
          </p>
          {/* <div className="stores">
            <div className="apple">Apple Store</div>
            <div className="android">Play Store</div>
          </div> */}
          <div className="stores">
            <a href="https://www.apple.com/app-store/" target="_blank" className="apple"></a>
            <a href="https://play.google.com/" target="_blank" className="android"></a>
          </div>
        </div>
        <div className="download-app-img-container">
          <img className="download-app-img" src={appss} alt="" />
        </div>
      </div>
    </section>
  );
};

export default DownloadApp;
