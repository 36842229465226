import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import './CardsWrap.css'
import axios from 'axios';
import HOST_URL from '../config';
import { Oval } from 'react-loader-spinner'

export default function PaperPartsCardsWrap({ paper }) {
    const [Data, setData] = useState([])
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        let isMounted = true;
        setLoading(true);
        if (isMounted) {
            getPaperParts(paper)
        }
        return () => {
            isMounted = false
        };
    }, [paper])



    async function getPaperParts(paper) {
        // API request
        await axios.get(`${HOST_URL}api/past-paper/show-paper-parts/` + paper).then(response => {
            setData(response.data);
            setLoading(false);
        })
    }

    return (
        isLoading ?
            <div className="spinner" style={{
                marginTop: "250px",
                marginBottom: "250px",
                display: "flex",
                justifyContent: "center"
            }}>
                <Oval
                    height={40}
                    width={40}
                    color="#4fa94d"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                    ariaLabel='oval-loading'
                    secondaryColor="#4fa94d"
                    strokeWidth={2}
                    strokeWidthSecondary={2}
                />
            </div>
            :
            (Data.length === 0) ?
                // return (
                <div className="spinner" style={{
                    marginTop: "250px",
                    marginBottom: "250px",
                    display: "flex",
                    justifyContent: "center",
                    fontSize: "20px"
                }}>
                    No Paper Parts
                </div>
                // )
                :
                <div className='cards-wrap'>
                    {
                        Data.map(item => {
                            return (
                                <div key={item.id} className="card">

                                    <img src={`${HOST_URL}storage/` + item.paper.image} alt="Past Paper" className="image" />

                                    <div className="content">
                                        <div className="label-wrap">
                                            {/* <Link
                                            to={{
                                                pathname: "/view-paper-video",
                                                state: { part: item.id }
                                            }}
                                            className="video-link"
                                        >
                                            <p className="label curved">Video</p>
                                        </Link>
                                        <img src="/images/video-circle.png" alt="Video Icon" className="video-icon" /> */}
                                        </div>

                                        <div className="sub-content">
                                            <p className="title">{item.paper.paper_name} {item.paper.year}</p>

                                            <div className='label-wrap'>
                                                <Link
                                                    to={{
                                                        pathname: "/view-paper-part",
                                                        state: { part: item.id }
                                                    }}
                                                    className="video-link">
                                                    <h1 style={{
                                                        width: "350%"
                                                    }}>{item.part}</h1>
                                                </Link>
                                            </div>

                                            <div className="labels">
                                                {/* <p className="label light-blue">{item.parts.length + " Parts"}</p> */}
                                                <div className='label-wrap'>
                                                    <Link
                                                        to={{
                                                            pathname: "/view-paper-part-answer-sheet",
                                                            state: { part: item.id }
                                                        }}
                                                        className="video-link">
                                                        <p className="label royal-blue">Answer sheet</p>
                                                    </Link>
                                                </div>
                                                <div className="label-wrap">
                                                    <Link
                                                        to={{
                                                            pathname: "/view-paper-video",
                                                            state: { part: item.id }
                                                        }}
                                                        className="video-link">
                                                        <p className="label purple">Paper discussion</p>
                                                    </Link>
                                                    <img src="/images/video-circle.png" alt="Video Icon" className="video-icon small" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
    )
}