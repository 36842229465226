import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import HOST_URL from '../config';
import { Oval } from 'react-loader-spinner'
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
function ResetPassword() {
  const [isLoading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const history = useHistory();


  const handleFormSubmit = (event) => {
    event.preventDefault();
    setLoading(true);

    let email = document.getElementById('email').value

    axios.post(`${HOST_URL}api/password/email`, {
      email: email,
    })
      .then(response => {
        // console.log("response.status")
        // console.log(response.status)
        // console.log("response.status")
        if (response.status == 200) {
          setLoading(false);
          setAlertMessage({ type: "success", text: 'Reset Link Sent to your email. Please check your email ...!', color: 'green' });
        }

        // else if (response.status === 422) {
        //   setLoading(false);
        //   setAlertMessage({ type: "error", text: 'Invalid Email.', color: 'red' });
        //   //   alert("Invalid Username or Password")
        // }
        
      }
      )
      .catch(error => {
        // console.log(error);
        setLoading(false);
        if (error.response.status === 422) {
          //   setLoading(false);
          setAlertMessage({ type: "error", text: 'Invalid Email.', color: 'red' });
          //   alert("Invalid Username or Password")
        }
        else {
          setLoading(false);
          setAlertMessage({ type: "success", text: 'Failed to Send Password Reset Email.', color: 'red' });
          //   alert("Failed to Login")
          //   window.location.reload('/login')
        }
      });
    // setErrors(Validation(values))
  };
  return (
    <div className='containerLogin'>
      {
        alertMessage && (
          <div style={{ color: alertMessage.color }}>
            {/* {alertMessage.text} */}
            <Stack sx={{ width: '100%' }} spacing={2}>
              <Alert severity={alertMessage.type}>{alertMessage.text}</Alert>
            </Stack>
          </div>
        )
      }
      <form onSubmit={handleFormSubmit}>
        <h1 className='heading-sign'>Reset Password</h1>
        <div className='ui devider'></div>
        <div className='ui form'>

          <div className="field">
            <label htmlFor="Email"></label>
            <input type="email" name='email' id='email' placeholder='Enter Email' />
          </div>
          <div className='loginButton-2'>
            {
              isLoading ? (
                <div className="spinner" style={{
                  marginBottom: "20px",
                  display: "flex",
                  justifyContent: "center"
                }}>
                  <Oval
                    height={40}
                    width={40}
                    color="#4fa94d"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                    ariaLabel='oval-loading'
                    secondaryColor="#4fa94d"
                    strokeWidth={2}
                    strokeWidthSecondary={2}

                  />
                </div>
              )
                : (
                  <div>
                    <button className="fluid ui blue basic button">Reset</button>
                  </div>
                )}
          </div>

        </div>

      </form>
    </div>

  )
}

export default ResetPassword
