import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import './CardsWrap.css'
import axios from 'axios';
import HOST_URL from '../config';
import { Oval } from 'react-loader-spinner'

export default function PaperAnswerSheetsCardsWrap({ paper }) {
    const [Data, setData] = useState([])
    const [isLoading, setLoading] = useState(false);


    useEffect(() => {
        let isMounted = true;
        setLoading(true);
        if (isMounted) {
            getPaperAnswerSheets(paper)
        }
        return () => {
            isMounted = false
        };
    }, [paper])



    async function getPaperAnswerSheets(paper) {
        // API request
        await axios.get(`${HOST_URL}api/past-paper/show-paper-answer-sheets/` + paper).then(response => {
            setData(response.data);
            setLoading(false);
        })
    }

    return (
        (isLoading) ?
            <div className="spinner" style={{
                marginTop: "250px",
                marginBottom: "250px",
                display: "flex",
                justifyContent: "center"
            }}>
                <Oval
                    height={40}
                    width={40}
                    color="#4fa94d"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                    ariaLabel='oval-loading'
                    secondaryColor="#4fa94d"
                    strokeWidth={2}
                    strokeWidthSecondary={2}
                />
            </div>
            :
            (Data.length === 0) ?
                // return (
                <div className="spinner" style={{
                    marginTop: "250px",
                    marginBottom: "250px",
                    display: "flex",
                    justifyContent: "center",
                    fontSize: "20px"
                }}>
                    No Answer Sheets
                </div>
                // )
                :
                <div className='cards-wrap'>
                    {
                        Data.map(item => {
                            return (
                                <div key={item.id} className="card">
                                    <Link
                                        to={{
                                            pathname: "/view-answer-sheet",
                                            state: { answer_sheet: item.id }
                                        }}
                                    >

                                        <img src={`${HOST_URL}storage/` + item.paper.image} alt="Past Paper" className="image" />

                                        <div className="content">
                                            <div className="label-wrap">
                                                {/* <Link to="" className="video-link"><p className="label curved">Video</p></Link> */}
                                                {/* <img src="/images/video-circle.png" alt="Video Icon" className="video-icon" /> */}
                                            </div>
                                            <div className="sub-content">
                                                <div className="labels">
                                                    <div className='label-wrap'>
                                                        <p className="title">{item.paper.paper_name}</p>
                                                    </div>
                                                </div>
                                                <div className='label-wrap'>
                                                    <p className="title">{item.part.part}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            )
                        })
                    }
                </div>
    )
}