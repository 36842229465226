import React from "react";
// import "./ContactUs.css";
// import { useHistory } from "react-router-dom";
import PaperPartsCardsWrap from './CardsWrap/PaperPartsCardsWrap'
import { useLocation } from 'react-router-dom';
const PaperParts = () => {
  const location = useLocation();
  const paper_id = location.state.paper;
  const paper_name = location.state.paper_name;
  const year = location.state.year;
  const medium = location.state.medium;
  return (
    <div style={{
      marginBottom: "100px"
    }}>
      <div style={{
        textAlign: "center"
      }}>
        <h1 className='hero-h1'>{paper_name} ({year}) ({medium} Medium)</h1>
      </div>
      <div
        style={{
          marginTop: "100px"
        }}>
        <PaperPartsCardsWrap
          paper={paper_id}
        />
      </div>
    </div>
  );
};

export default PaperParts;
