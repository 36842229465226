import React, { useState, useEffect } from 'react';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import axios from 'axios';
import HOST_URL from './config';
import { useHistory } from "react-router-dom";
import './CustomSearch.css';

const CustomSearch = () => {
  const [Categories, setCategories] = useState([]);
  const [Category, setCategory] = useState('');
  // const [ParentSubCategories, setParentSubCategories] = useState([]);
  // const [ParentSubCategory, setParentSubCategory] = useState('');
  const [SubCategories, setSubCategories] = useState([]);
  const [Subcategory, setSubCategory] = useState('');
  const [Subjects, setSubjects] = useState([]);
  const [Subject, setSubject] = useState('');
  const [Papers, setPapers] = useState([]);
  const [Paper, setPaper] = useState('');
  const [PaperName, setPaperName] = useState('');
  const [PaperYear, setPaperYear] = useState('');
  const [Mediums, setMediums] = useState([]);
  const [Medium, setMedium] = useState('');
  const [MediumName, setMediumName] = useState('');
  const history = useHistory();
  // const [Medium, setMedium] = useState('');

  useEffect(() => {
    let isMounted = true;
    getMediums().then(res => {
      if (isMounted) {
        if (res) {
          setMediums(res.data);
        }
      }
    });
    return () => {
      isMounted = false;
    };
  }, []);

  async function getMediums() {
    // API request
    const response = await axios.get(`${HOST_URL}api/past-paper/mediums`)
    return response
  }

  const handleMediumChange = (event) => {
    // localStorage.setItem('mediumId', event.target.value);
    let medium_id = event.target.value
    const selectedMedium = Mediums.find(medium => medium.id === medium_id);
    const medium_name = selectedMedium ? selectedMedium.medium : "";
    setMedium(event.target.value)
    setMediumName(medium_name)
    let year = PaperYear;
    let subject = Subject;
    // alert(category)
    getPapers(subject, medium_id, year);
  }

  // useEffect(() => {
  //   let medium_id = localStorage.getItem('mediumId');
  //   if (medium_id != null || medium_id) {
  //     setMedium(medium_id)
  //   }
  //   else {
  //     setMedium('1')
  //   }
  // }, [Medium]);

  useEffect(() => {
    let isMounted = true;
    getCategories().then(res => {
      // setLoading(true);
      if (isMounted) {
        if (res) {
          setCategories(res.data);
          // setLoading(false);
        }
        else {
          // setLoading(true);
        }
      }
    });
    return () => {
      isMounted = false;
    };
    // getRandomPapers();
  }, []);

  async function getCategories() {
    // API request
    const response = await axios.get(`${HOST_URL}api/past-paper/categories`)
    return response;
  }

  function handleCategoryChange(event) {
    let category = event.target.value;
    setCategory(category)
    // alert(category)
    getParentSubCategories(category);

  }

  async function getParentSubCategories(category) {
    // API request
    await axios.get(`${HOST_URL}api/past-paper/show-sub-categories/` + category).then(response => {
      setSubCategories(response.data);
      // console.log(response.data)
      // setLoading(false);
    })
  }

  // function handleParentSubCategoryChange(event) {
  //   let parent_sub_category = event.target.value;
  //   setParentSubCategory(parent_sub_category)
  //   // alert(category)
  //   getSubCategories(parent_sub_category);

  // }


  // async function getSubCategories(parent_sub_category) {
  //   // API request
  //   await axios.get(`${HOST_URL}api/past-paper/sub-categories/` + parent_sub_category).then(response => {
  //     setSubCategories(response.data);
  //     // setLoading(false);
  //   })
  // }

  function handleSubCategoryChange(event) {
    let sub_category_id = event.target.value;
    setSubCategory(sub_category_id)
    // setSubjects(sub_category_id)
    // alert(category)
    getSubjects(sub_category_id);

  }

  async function getSubjects(sub_category_id) {
    // API request
    await axios.get(`${HOST_URL}api/past-paper/subjects/` + sub_category_id).then(response => {
      setSubjects(response.data);
      // setLoading(false);
    })
  }

  function handleSubjectsChange(event) {
    let subject = event.target.value;
    let medium_id = Medium;
    let year = PaperYear;
    setSubject(subject)
    // alert(category)
    getPapers(subject, medium_id, year);
  }
  function handleTextChange(event) {
    let year = event.target.value;
    setPaperYear(year)
    let subject = Subject;
    let medium_id = Medium;
    // alert(category)
    getPapers(subject, medium_id, year);
  }


  function getPapers(subject, medium_id, year) {
    // API request
    axios.get(`${HOST_URL}api/past-paper/papers/subject/` + subject + `/medium/` + medium_id + `/year/` + year).then(response => {
      setPapers(response.data);
      // console.log(response.data)
      // setLoading(false);

    })
  }

  function handlePapersChange(event) {
    let paper_id = event.target.value;
    // let medium_id = Medium;
    // const selectedValue = event.target.value;
    const selectedPaper = Papers.find(paper => paper.id === paper_id);
    const paper_name = selectedPaper ? selectedPaper.paper_name : "";
    const paper_year = selectedPaper ? selectedPaper.year : "";
    // console.log(`Selected value: ${paper_id}`);
    // console.log(`Selected paper name: ${paperName}`);
    // setPaper(paper_id)
    // setPaperName(paper_name)
    // setPaperYear(paper_year)
    // alert(category)
  }

  function handleFormSubmit(event) {
    event.preventDefault();
    const formData = new FormData(event.target);
    const formValues = Object.fromEntries(formData.entries());

    // if (Paper != '' || Paper != null || Paper) {
    //   // history.push(`/paper-parts`);
    //   history.push({
    //     pathname: "/paper-parts",
    //     state: {
    //       paper: Paper,
    //       paper_name: PaperName,
    //       year: PaperYear,
    //       medium: MediumName
    //     },
    //   });
    // }
    // else {
      history.push({
        pathname: "/paper-result",
        state: {
          category: Category,
          sub_category: Subcategory,
          subject: Subject,
          year: PaperYear,
          medium: Medium,
        },
      });
    // }

  }

  const startYear = 1900;
  const currentYear = new Date().getFullYear();
  const years = [];

  for (let year = currentYear; year >= startYear; year--) {
    years.push(year);
  }

  return (
    <div
      className='custom_search'
    >
      <form onSubmit={handleFormSubmit}>

        <div style={{
          marginLeft: "45px",
        }}>

          <h1 style={{
            // position: "absolute",
            width: "100%",
            height: "auto",
            //  fontFamily: "Poppins",
            //  fontStyle: "normal",
            fontWeight: "500",
            fontSize: "36px",
            lineHeight: "180%",
            display: "flex",
            alignItems: "center"
          }}>Search For A Past Paper</h1>
        </div>

        <div
          style={{
            display: "flex"
          }}
          className='all-sub-container'
        >
          <div
            style={{
              // width: "15%",
              // marginRight: "10px"
            }}
            className='dropdown_label_container'
          >
            <label
              style={{
                marginLeft: "25px",
              }}>
              Exam Body
            </label>
            <div className='dropdown'>
              <div style={{ width: "100%" }}>
                <FormControl style={{
                  width: "100%"
                }}>
                  <InputLabel id="demo-simple-select-label">Select</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    // value={Medium}
                    label="Select medium"
                    onChange={handleCategoryChange}
                  // required
                  >

                    {
                      (Categories == '' || Categories == null) ?
                        // return (
                        <MenuItem>
                          No Exam Body Found
                        </MenuItem>
                        // );

                        :
                        Categories.map((item) => {
                          // i = i + 1
                          return (
                            <MenuItem key={item.id} value={item.id}>
                              {item.category}
                            </MenuItem>
                          );
                        })
                    }
                  </Select>
                </FormControl>
              </div>
            </div>
          </div>

          <div
            style={{
              // width: "15%",
              // marginRight: "10px"
            }}
            className='dropdown_label_container'
          >
            <label>
              Exam
            </label>
            <div className='dropdown'>
              <div style={{ width: "100%" }}>
                <FormControl style={{
                  width: "100%"
                }}>
                  <InputLabel id="demo-simple-select-label">Select</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    // value={Medium}
                    label="Select Parent Sub Category"
                    onChange={handleSubCategoryChange}
                  // required
                  >
                    {
                      (SubCategories == '' || SubCategories == null) ?
                        // return (
                        <MenuItem>
                          No Exams Found
                        </MenuItem>
                        // );

                        :
                        SubCategories.map((item) => {



                          let parent = '';
                          if (item.parent_sub_category_id === null) {
                            parent = '';
                            // i = i + 1
                            return (
                              <MenuItem key={item.id} value={item.id}>
                                {item.name}
                              </MenuItem>
                            );
                          }
                          else {
                            parent = item.parent.name;
                            // i = i + 1
                            return (
                              <MenuItem key={item.id} value={item.id}>
                                {item.name} ({parent})
                              </MenuItem>
                            );
                          }

                        })
                    }
                  </Select>
                </FormControl>
              </div>
            </div>
          </div>

          {/* <div
            style={{
              // width: "15%",
              // marginRight: "10px"
            }}
            className='dropdown_label_container'
          >
            <label>
              Sub Category 2
            </label>
            <div className='dropdown'>
              <div style={{ width: "100%" }}>
                <FormControl style={{
                  width: "100%"
                }}>
                  <InputLabel id="demo-simple-select-label">Select</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    // value={Medium}
                    label="Select Sub Category"
                    onChange={handleSubCategoryChange}
                    required
                  >
                    {
                      SubCategories.map((item) => {
                        // i = i + 1
                        return (
                          <MenuItem key={item.id} value={item.id}>
                            {item.name}
                          </MenuItem>
                        );
                      })
                    }
                  </Select>
                </FormControl>
              </div>
            </div>
          </div> */}

          {/* <div
            style={{
              // width: "15%",
              // marginRight: "10px"
            }}
            className='dropdown_label_container'
          >
            <label>
              Year
            </label>
            <div className='dropdown'>
              <div style={{ width: "100%" }}>
                <FormControl style={{
                  width: "100%"
                }}>
                  {/* <InputLabel id="demo-simple-select-label">Select</InputLabel> */}
          {/* <input type="text" name="year" id="year" className="text-box-white" placeholder="Year" required onChange={handleTextChange} /> */}
          {/* </FormControl> */}
          {/* </div> */}
          {/* </div> */}
          {/* </div>  */}

          <div
            style={{
              // width: "15%",
              // marginRight: "10px"
            }}
            className='dropdown_label_container'
          >
            <label
              style={{
                marginLeft: "25px",
              }}>
              Year
            </label>
            <div className='dropdown'>
              <div style={{ width: "100%" }}>
                <FormControl style={{
                  width: "100%"
                }}>
                  <InputLabel id="demo-simple-select-label">Year</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    // value={Medium}
                    label="Select year"
                    onChange={handleTextChange}
                  // required
                  >
                    {
                      (years == '' || years == null) ?
                        // return (
                        <MenuItem>
                          No Years Found
                        </MenuItem>
                        // );

                        :
                        years.map((year) => {
                          // i = i + 1
                          return (
                            <MenuItem key={year} value={year}>
                              {year}
                            </MenuItem>
                          );
                        })
                    }
                  </Select>
                </FormControl>
              </div>
            </div>
          </div>

          <div
            style={{
              // width: "15%",
              // marginRight: "10px"
            }}
            className='dropdown_label_container'
          >
            <label
              style={{
                marginLeft: "25px",
              }}>
              Medium
            </label>
            <div className='dropdown'>
              <div style={{ width: "100%" }}>
                <FormControl style={{
                  width: "100%",
                }} className='dropdown_menu'>
                  <InputLabel id="demo-simple-select-label">Select Medium</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={Medium}
                    label="Select medium"
                    onChange={handleMediumChange}
                  // required
                  >
                    {
                      (Mediums == '' || Mediums == null) ?
                        // return (
                        <MenuItem>
                          No Mediums Found
                        </MenuItem>
                        // );

                        :
                        Mediums.map((item) => {
                          // i = i + 1
                          return (
                            <MenuItem key={item.id} value={item.id}>
                              {item.medium}
                            </MenuItem>
                          );
                        })
                    }
                  </Select>
                </FormControl>
              </div>
            </div>
          </div>

          <div
            style={{
              // width: "15%",
              // marginRight: "10px"
            }}
            className='dropdown_label_container'
          >
            <label>
              Subject
            </label>
            <div className='dropdown'>
              <div style={{ width: "100%" }}>
                <FormControl style={{
                  width: "100%"
                }}>
                  <InputLabel id="demo-simple-select-label">Subject</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    // value={Medium}
                    label="Select Subject"
                    onChange={handleSubjectsChange}
                  // required
                  >
                    {
                      (Subjects == '' || Subjects == null) ?
                        // return (
                        <MenuItem>
                          No Subjects Found
                        </MenuItem>
                        // );

                        :
                        Subjects.map((item) => {
                          // i = i + 1
                          return (
                            <MenuItem key={item.id} value={item.id}>
                              {item.subject}
                            </MenuItem>
                          );
                        })
                    }
                  </Select>
                </FormControl>
              </div>
            </div>
          </div>

          {/* <div
            style={{
              // width: "15%",
              // marginRight: "10px"
            }}
            className='dropdown_label_container'
          >
            <label>
              Paper
            </label>
            <div className='dropdown'>
              <div style={{ width: "100%" }}>
                <FormControl style={{
                  width: "100%"
                }}>
                  <InputLabel id="demo-simple-select-label">Paper</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    // value={Medium}
                    label="Select Paper"
                    onChange={handlePapersChange}
                  // required
                  >
                    {
                      (Papers == '' || Papers == null) ?
                        // return (
                        <MenuItem>
                          No Papers Found
                        </MenuItem>
                        // );

                        :
                        Papers.map((item) => {
                          // i = i + 1
                          return (
                            <MenuItem key={item.id} value={item.id}>
                              {item.paper_name} ({item.year})
                            </MenuItem>
                          );

                        })
                    }
                  </Select>
                </FormControl>
              </div>
            </div>
          </div> */}
        </div>

        <div
          style={{
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            marginTop: "15px"
          }}
        >
          <input type='submit'
            style={{
              background: "#EC5F5F",
              borderRadius: "4px",
              width: "145px",
              height: "48px",
              cursor: "pointer",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              padding: "0px 32px 0px 24px",
              gap: "8px"
            }}
          ></input>
        </div>
      </form>

    </div >
    // <div className='search-box-conatiner'>
    //     This will be a form
    // </div>
  )
}

export default CustomSearch
