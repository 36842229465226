import React from "react";
// import "./ContactUs.css";
// import { useHistory } from "react-router-dom";
import SearchResultsCardsWrap from './CardsWrap/SearchResultsCardsWrap'
import { useLocation } from 'react-router-dom';
const PapersSearchResult = () => {
  const location = useLocation();
  // const subject_name = location.state.subject_name;
  const category = location.state.category;
  const sub_category = location.state.sub_category;
  const subject_id = location.state.subject;
  const year = location.state.year;
  const medium_id = location.state.medium;
  // subject_name: item.subject, sub_category: item.sub_category.name
  return (
    <div style={{
      marginBottom: "100px"
    }}>
      <div style={{
        textAlign: "center"
      }}>
        {/* <h1 className='hero-h1'>Papers of {sub_category} {subject_name}</h1> */}
      </div>
      <div
        style={{
          marginTop: "100px"
        }}>
        <SearchResultsCardsWrap
          category={category}
          sub_category={sub_category}
          subject={subject_id}
          year={year}
          medium_id={medium_id}
        />
      </div>
    </div>
  );
};

export default PapersSearchResult;
