// import React from 'react';
import React, { useState, useEffect } from 'react';
import '../App.css';
import './HeroSection.css';
// import './HeroSection.css';
import { Button1 } from './Button1';
import ImageSlider from './ImageSlider'
// import CustomSearch from './CustomSearch'
import { SliderData } from './SliderData';
import CustomSearch from './CustomSearch'
import WhyChooseUs from './WhyChooseUs';
import Discussion from './Discussion';
// import CardsWrap from './CardsWrap/CardsWrap'
import RandomPapersCardsWrap from './CardsWrap/RandomPapersCardsWrap'
import CategoriesCardsWrap from './CardsWrap/CategoriesCardsWrap'
import DownloadApp from './DownloadApp';
import ContactUs from './ContactUs';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import axios from 'axios';
import HOST_URL from './config';
import LoginStatus from './UserAuth';
import { Oval } from 'react-loader-spinner'


function HeroSection() {
  // const Data = [
  //   {
  //     title: 'Test A/L title',
  //     image: '/images/img-9.jpg'
  //   },
  //   {
  //     title: 'Test A/L title',
  //     image: '/images/img-9.jpg'
  //   },
  //   {
  //     title: 'Test A/L title',
  //     image: '/images/img-9.jpg'
  //   },
  //   {
  //     title: 'Test A/L title',
  //     image: '/images/img-9.jpg'
  //   },
  //   {
  //     title: 'Test A/L title',
  //     image: '/images/img-9.jpg'
  //   }
  // ]

  const [Categories, setCategories] = useState([]);
  const [Mediums, setMediums] = useState([]);
  const [Medium, setMedium] = useState('');

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const isLoggedInUserAuth = LoginStatus();

  const [isLoading, setLoading] = useState(true);

  // const history = useHistory();
  // const [RandomPapers, setRandomPapers] = useState([]);
  // const [categoryId, setCategoryId] = useState('');
  useEffect(() => {
    let isMounted = true;
    getCategories().then(res => {
      // setLoading(true);
      if (isMounted) {
        if (res) {
          setCategories(res.data);
          setLoading(false);
        }
        else {
          setLoading(true);
        }
      }
    });
    return () => {
      isMounted = false;
    };
    // getRandomPapers();
  }, []);

  useEffect(() => {
    let isMounted = true;
    getMediums().then(res => {
      if (isMounted) {
        if (res) {
          setMediums(res.data);
        }
      }
    });
    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    let medium_id = localStorage.getItem('mediumId');
    if (medium_id != null || medium_id) {
      setMedium(medium_id)
    }
    else {
      setMedium('1')
    }
  }, [Medium]);

  useEffect(() => {
    setIsLoggedIn(isLoggedInUserAuth)
  }, [isLoggedInUserAuth]);


  // async function getSliders() {
  //   // API request
  //   const response = await axios.get(`${HOST_URL}api/past-paper/web-slides`)
  //   return response;
  // }

  async function getCategories() {
    // API request
    const response = await axios.get(`${HOST_URL}api/past-paper/categories`)
    return response;
  }

  async function getMediums() {
    // API request
    const response = await axios.get(`${HOST_URL}api/past-paper/mediums`)
    return response
  }

  const handleMediumChange = (event) => {
    localStorage.setItem('mediumId', event.target.value);
    setMedium(event.target.value)
  }

  return (
    <React.Fragment>
      <div
        style={{
          width: "100%",
          marginBottom: "8px"
        }}
      >
        <ImageSlider />
      </div>
      {/* <div
        style={{
          position: "absolute",
          width: "1683px",
          height: "256px",
          background: "#E7E7E7",
        }}>

      </div> */}
      <div
        style={{
          width: "100%",
          marginBottom: "8px"
        }}>
        <CustomSearch />
      </div>


      <div>
        <div className='home-main-types'>
          <div className='mediums-container'>
            <div style={{ width: "100%" }}>
              <FormControl style={{
                width: "100%"
              }}>
                <InputLabel id="demo-simple-select-label">Select Medium</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={Medium}
                  label="Select medium"
                  onChange={handleMediumChange}
                >
                  {
                    Mediums.map((item) => {
                      // i = i + 1
                      return (
                        <MenuItem key={item.id} value={item.id}>
                          {item.medium}
                        </MenuItem>
                      );
                    })
                  }
                </Select>
              </FormControl>
            </div>
          </div>
          <div>
            <h1 className='hero-h1'>Past Papers</h1>
          </div>
          <div>
            <p>Unlock Your Potential with Interactive Learning</p>
          </div>
        </div>
        {/* <div className="row-fluid-1">
          * <a className="btn pull-left">All</a> 

          <a className="btn pull-left">All</a>
          <a className="btn">Most Popular</a>
          <a className="btn">Trending </a>
          <a className="btn">Just Added</a>
        </div> */}
      </div>

      {
        (isLoading) ?
          <div className="spinner" style={{
            // marginBottom: "20px"
            marginTop: "100px",
            marginBottom: "100px",
            display: "flex",
            justifyContent: "center"
          }}>
            <Oval
              height={40}
              width={40}
              color="#4fa94d"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
              ariaLabel='oval-loading'
              secondaryColor="#4fa94d"
              strokeWidth={2}
              strokeWidthSecondary={2}

            />
          </div>
          :
          <CategoriesCardsWrap data={Categories} medium={Medium} />

      }
      <WhyChooseUs />
      {
        Categories.map((category) => {
          // console.log(category.id)
          // categoryId = category.id
          return (
            <div key={category.id} >
              <div className='home-main-types'>
                <h1 className='hero-h1'>{category.category}</h1>
                <p>Experience the live 1:1 class for kids to explore their hobby & talent</p>
                {/* <div className="row-fluid-1">
                  <a className="btn pull-left">All</a>
                  <a className="btn">Most Popular</a>
                  <a className="btn">Trending </a>
                  <a className="btn">Just Added</a>
                </div> */}
              </div>

              <RandomPapersCardsWrap
                category={category.id} medium={Medium}
              />
              {/* <div className='btn-more-div'>
                <button className='btn-more'>View All</button>
              </div> */}
            </div>
          )
        })
      }

      {/* <div className='home-main-types'>
        <h1 className='hero-h1'>School Pastpapers 2</h1>
        <p>Experience the live 1:1 class for kids to explore their hobby & talent</p>
        <div class="row-fluid-1">
          <a className="btn pull-left">All</a>
          <a className="btn">Most Popular</a>
          <a className="btn">Trending </a>
          <a className="btn">Just Added</a>
        </div>
      </div>

      <CardsWrap data={Data} />
      <div className='btn-more-div'>
        <button className='btn-more'>View All</button>
      </div> */}
      <Discussion />
      <div className='home-main-types'>
        <h1 className='hero-h1'>Our Partners</h1>
        <p>Our partners will have below</p>
        {/* <div className="row-fluid-1">
          <div className="one">All</div>
          <div className="two">Most Popular</div>
          <div className="three">Trending </div>
          <div className="four">Just Added</div>
        </div> */}
      </div>
      <DownloadApp />
      <div style={{
        marginTop: "25px",
        marginLeft: "15px"
      }}>
        <ContactUs />
      </div>
    </React.Fragment >
  );
}

export default HeroSection;
