import React from 'react'
import './Community.css'
import ChatBody from "../../components/chatBody/ChatBody";


const Community = () => {
  return (
    <div className='__main'>
      <ChatBody />
    </div>
  )
}

export default Community