import React from "react";
import { useLocation } from 'react-router-dom';
import PaperDataCardsWrap from "./CardsWrap/PaperDataCardsWrap";
const Papers = () => {
  const location = useLocation();
  const paper_id = location.state.paper_id;
  const paper_name = location.state.paper_name;
  const paper_image = location.state.image;
  const year = location.state.year;
  const medium = location.state.medium

  // subject_name: item.subject, sub_category: item.sub_category.name
  return (
    <div style={{
      marginBottom: "100px"
    }}>
      <div style={{
        textAlign: "center"
      }}>
        <h1 className='hero-h1'>Choose what you want to See.</h1>
      </div>
      <div
        style={{
          marginTop: "100px"
        }}>
        <PaperDataCardsWrap
          paper_id={paper_id}
          paper_name={paper_name}
          image={paper_image}
          year={year}
          medium={medium}
        />
      </div>
    </div>
  );
};

export default Papers;
