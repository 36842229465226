import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { Oval } from 'react-loader-spinner'
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import HOST_URL from './config';

const SubscriptionMessage = () => {
    const [Data, setData] = useState('')
    const [isLoading, setLoading] = useState(false);

    const history = useHistory();

    useEffect(() => {
        let isMounted = true;
        setLoading(true);
        if (isMounted) {
            getSubscriptionResult()
        }
        return () => {
            isMounted = false
        };
    }, [])

    async function getSubscriptionResult() {
        // const token = localStorage.getItem('token');
        // API request
        await axios.get(`${HOST_URL}api/check-validity`, {
            // headers: { 'Authorization': `Bearer ${token}` },
        }).then(response => {
            let hh = response.data
            console.log(hh)
            setData(response.data);
            setLoading(false);
        })
    }

    const handleButtonClick = () => {
        // Redirect to the login page
        // window.location.href = '/login';
        // history.push('/login');
    };
    return (
        <div>
            <div style={styles.container}>
                <div style={styles.content}>
                    {Data == true 
                    ? 
                    <h2>Payment Successfull!</h2>
                    : <h2>Payment Failed!</h2>}
                    
                    {/* <button style={styles.button} onClick={handleButtonClick}>Continue</button> */}
                </div>
            </div>
        </div>

    );
}
const styles = {
    container: {
        marginTop: '230px',
        marginBottom: '230px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        // height: '100vh',
    },
    content: {
        backgroundColor: '#f2f2f2',
        padding: '20px',
        borderRadius: '10px',
    },
    button: {
        backgroundColor: 'red',
        color: 'white',
        padding: '10px 20px',
        borderRadius: '5px',
        border: 'none',
        cursor: 'pointer',
        marginTop: '20px',
    },
};
export default SubscriptionMessage;
