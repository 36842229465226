import React from "react";
// import "./ContactUs.css";
// import { useHistory } from "react-router-dom";
import ParentSubCategoriesCardsWrap from './CardsWrap/ParentSubCategoriesCardsWrap'
import { useLocation } from 'react-router-dom';
const ParentSubCategories = () => {
  // const history = useHistory();
  // const state = history.location.state || {};
  // let categoryId = state.categoryId;
  const location = useLocation();
  const category_id = location.state.category_id;
  const category = location.state.category;
  const medium = location.state.medium;
  return (

    <div style={{
      marginBottom: "100px"
    }}>
      <div style={{
        textAlign: "center"
      }}>
        <h1 className='hero-h1'>Sub Categories of {category}</h1>
      </div>
      <div 
      style={{
        marginTop: "100px"
      }}>
        <ParentSubCategoriesCardsWrap
          category_id={category_id}
          medium={medium}
        />
      </div>
    </div>
  );
};

export default ParentSubCategories;
