import React, { useState, useEffect } from 'react';
// import { SliderData } from './SliderData';
import './Slider.css'
import axios from 'axios';
import HOST_URL from './config';
import Carousel from 'react-material-ui-carousel'
import LoginStatus from './UserAuth';
import { Link } from 'react-router-dom';
import { Oval } from 'react-loader-spinner'


const ImageSlider = () => {
  const [Data, setData] = useState([])
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const isLoggedInUserAuth = LoginStatus();
  const [isLoading, setLoading] = useState(false);


  useEffect(() => {
    setIsLoggedIn(isLoggedInUserAuth)
  }, [isLoggedInUserAuth]);

  useEffect(() => {
    setLoading(true);
    let isMounted = true;
    if (isMounted) {
      getSliders()
    }
    // if(Array.isArray(data) && isMounted) setData(data)

    return () => {
      isMounted = false
    };
  }, []);

  async function getSliders() {
    // API request
    await axios.get(`${HOST_URL}api/past-paper/slides`).then(response => {
      setData(response.data);
      setLoading(false);
    })
  }

  return (
    (isLoading) ?
      <div className="spinner" style={{
        marginBottom: "200px",
        marginTop: "200px",
        display: "flex",
        justifyContent: "center"
      }}>
        <Oval
          height={40}
          width={40}
          color="#4fa94d"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
          ariaLabel='oval-loading'
          secondaryColor="#4fa94d"
          strokeWidth={2}
          strokeWidthSecondary={2}

        />
      </div>
      :

      <Carousel
      // fullHeightHover={false}
      >
        {Data.map((slide, index) => {
          const isFirstSlide = index === 0;
          return (
            <div
              key={slide.id}
              style={{ position: 'relative', width: '100%', height: '100vh' }}
            >
              {
                // <div
                //   style={{ position: 'relative', width: '100%' }}
                // >

                <img src={`${HOST_URL}storage/` + slide.image} alt='travel image' className='image' style={{
                  width: "100%",
                  // height: "100vh",
                  // height: '100%',
                  objectFit: 'cover'
                }} />


              }
              {
                isFirstSlide && !isLoggedIn && (
                  // (isLoggedIn === true) ?
                  // null
                  // :
                  <Link to="/sign-up">
                    <button className="get-started-btn"
                      style={{
                        position: 'absolute',
                        top: '80%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        zIndex: 1,
                      }}
                    >GET STARTED</button>
                  </Link>


                  // <Button component={Link} to="/sign-up" variant="contained" size="large"
                  //   style={{
                  //     position: 'absolute',
                  //     top: '70%',
                  //     left: '50%',
                  //     transform: 'translate(-50%, -50%)',
                  //     display: 'flex',
                  //     alignItems: 'center',
                  //     justifyContent: 'center',
                  //     zIndex: 1,
                  //   }}
                  // >GET STARTED</Button>

                  // </div>
                )}
            </div>
          );
        })}
      </Carousel >
  );

};

export default ImageSlider;