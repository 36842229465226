import React from 'react'
import './WhyChooseUs.css'
import aboutus from '../about-img.jpeg';
const WhyChooseUs = () => {
  return (
    <section className='choose'>
      <div className="main-choose">
        <div className='choose-text'>
          <h1>Why Choose Us</h1>
          <p>
            Whether you are preparing for O/L, A/L, university exams, or professional qualifications such as ACCA, CIMA, or CIM, and Public Examinations, PassPaper.lk has everything you need to succeed. With a user-friendly interface, easy navigation, and a vast database of resources, PassPaper.lk is an excellent tool for anyone looking to improve their exam performance and achieve their academic goals
          </p>
        </div>
        <div>
          <img className='choose-img' src={aboutus} alt="" />
        </div>

      </div>
    </section>
  )
}

export default WhyChooseUs
