import React from "react";
// import "./ContactUs.css";
// import { useHistory } from "react-router-dom";
import SubjectsCardsWrap from './CardsWrap/SubjectsCardsWrap'
import { useLocation } from 'react-router-dom';
const Subjects = () => {
  const location = useLocation();
  const sub_category_id = location.state.sub_category_id;
  const sub_category = location.state.sub_category;
  const category = location.state.category;
  const parent_sub_category = location.state.parent_sub_category;


  return (
    <div style={{
      marginBottom: "100px"
    }}>
      <div style={{
        textAlign: "center"
      }}>
        <h1 className='hero-h1'>Subjects of {parent_sub_category} {sub_category} ({category})</h1>
      </div>
      <div
        style={{
          marginTop: "100px"
        }}>
        <SubjectsCardsWrap
          sub_category_id={sub_category_id}
        />
      </div>
    </div>
  );
};

export default Subjects;
