import React from 'react'
import './Discussion.css'
import discussion from '../paper-discussion-img.png';
// import aboutus from '..about-img.jpeg';

const Discussion = () => {
  return (
    <section className='discussion'>
      <div className="main-discussion">
        <div className='discussion-img-container'>
          <img className="discussion-img" src={discussion} alt="" />
        </div>
        <div className='discussion-text'>
          <h1 className='discussion-text-h1'>Paper Discussion</h1>
          <p> PassPaper.lk offers paper discussion videos for various subjects, which are conducted by experienced teachers and experts in the relevant fields. These videos provide valuable insights and tips on how to approach different types of questions and help students understand difficult concepts in a more accessible way. And we provide individual paper discussion sessions with qualified instructors.
          </p>
          <br></br>
          <div className='btn-know-container'>
            <button className='btn-know'>Know More</button>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Discussion
