import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';

const AuthFailed = () => {
    const history = useHistory();

    const handleButtonClick = () => {
        // Redirect to the login page
        // window.location.href = '/login';
        history.push('/login');
    };
    return (
        <div>
            <div style={styles.container}>
                <div style={styles.content}>
                    <h2>Please login & subscribe to view the Papers, Answer sheet and Video</h2>
                     <button style={styles.button} onClick={handleButtonClick}>Continue</button>
                </div>
            </div>
        </div>

    );
}
const styles = {
    container: {
        marginTop: '230px',
        marginBottom: '230px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        // height: '100vh',
    },
    content: {
        backgroundColor: '#f2f2f2',
        padding: '20px',
        borderRadius: '10px',
    },
    button: {
        backgroundColor: 'red',
        color: 'white',
        padding: '10px 20px',
        borderRadius: '5px',
        border: 'none',
        cursor: 'pointer',
        marginTop: '20px',
    },
};
export default AuthFailed;
