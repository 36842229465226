import React from "react";
// import "./ContactUs.css";
// import { useHistory } from "react-router-dom";
import PapersCardsWrap from './CardsWrap/PapersCardsWrap'
import { useLocation } from 'react-router-dom';
const Papers = () => {
  const location = useLocation();
  const subject_id = location.state.subject;
  const medium_id = location.state.medium;
  const subject_name = location.state.subject_name;
  const sub_category = location.state.sub_category;
  // subject_name: item.subject, sub_category: item.sub_category.name
  return (
    <div style={{
      marginBottom: "100px"
    }}>
      <div style={{
        textAlign: "center"
      }}>
        <h1 className='hero-h1'>Papers of {sub_category} {subject_name}</h1>
      </div>
      <div
        style={{
          marginTop: "100px"
        }}>
        <PapersCardsWrap
          subject={subject_id}
          medium_id={medium_id}
        />
      </div>
    </div>
  );
};

export default Papers;
