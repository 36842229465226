import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import './CardsWrap.css'
import axios from 'axios';
import HOST_URL from '../config';
import { Oval } from 'react-loader-spinner'

export default function RandomPapersCardsWrap({ category, medium }) {
    const [Data, setData] = useState([])
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true)
        let isMounted = true;

        if (isMounted) {
            getRandomPapers(medium)
        }
        // if(Array.isArray(data) && isMounted) setData(data)

        return () => {
            isMounted = false
        };
    }, [medium])



    async function getRandomPapers(medium) {
        // API request
        await axios.get(`${HOST_URL}api/past-paper/category/` + category + `/medium/` + medium + ``).then(response => {
            setData(response.data);
            setLoading(false)
            // console.log(response.data)
        })
    }

    return (
        isLoading ?
            <div className="spinner" style={{
                marginTop: "100px",
                marginBottom: "100px",
                display: "flex",
                justifyContent: "center"
            }}>
                <Oval
                    height={40}
                    width={40}
                    color="#4fa94d"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                    ariaLabel='oval-loading'
                    secondaryColor="#4fa94d"
                    strokeWidth={2}
                    strokeWidthSecondary={2}
                />
            </div>
            :
            (Data.length === 0) ?
                // return (
                <div className="spinner" style={{
                    marginTop: "100px",
                    marginBottom: "100px",
                    display: "flex",
                    justifyContent: "center",
                    fontSize: "20px"
                }}>
                    No Papers
                </div>
                // )
                :
                <div className='cards-wrap'>
                    {
                        Data.map(item => {
                            let parent = '';
                            if (item.sub_category.parent_sub_category_id === null) {
                                parent = '';
                            }
                            else {
                                parent = item.sub_category.parent.name;
                            }
                            // console.log(item.sub_category.parent.name)
                            return (
                                <div key={item.id} className="card">
                                    <Link
                                        to={{
                                            pathname: "/paper-data",
                                            state: { paper_id: item.id, paper_name: item.paper_name, image: item.image, year: item.year, medium: item.medium.medium }
                                        }}
                                        className="video-link">
                                        <img src={`${HOST_URL}storage/` + item.image} alt="Past Paper" className="image" />

                                        <div className="content">
                                            <div className="label-wrap">
                                            </div>
                                            <div className="sub-content">
                                                <div className="">
                                                    <p className="title">{item.paper_name} {item.year}
                                                        ( {parent}  {item.sub_category.name} - {item.subject.subject})</p>
                                                </div>

                                                {/* <p className="title">{item.paper_name} {item.year} {item.sub_category.name}</p> */}
                                                <div className="labels">
                                                    <div className="label-wrap">
                                                        <p className="label light-blue">{item.parts.length + " Parts"}</p>
                                                    </div>
                                                    <div className='label-wrap'>
                                                        <p className="label royal-blue">{item.answer_sheets.length + " Answer sheets"}</p>
                                                    </div>
                                                    <div className="label-wrap">
                                                        <p className="label purple">Paper discussion</p>
                                                        <img src="/images/video-circle.png" alt="Video Icon" className="video-icon small" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            )
                        })
                    }
                </div>
    )
}