import React from 'react';
import Navbar from './components/Navbar';
import './App.css';
import Home from './components/pages/Home';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Alevel from './components/pages/Alevel';
import Olevel from './components/pages/Olevel';
import Footer from './components/Footer';
import SchoolExam from './components/pages/SchoolExam';
import Professional from './components/pages/Professional';
import Login from './components/pages/Login';
import ResetPassword from './components/pages/resetPassword';
import Bookalivedisplay from './components/chat/Bookalivedisplay';
import Community from './components/pages/Community';
import SearchPaper from './components/SearchPaper';
import Register from './components/pages/Register';
import AboutUs from './components/AboutUs';
import ContactUs from './components/ContactUs';
import SubCategories from './components/SubCategories';
import ParentSubCategories from './components/ParentSubCategories';
import Subjects from './components/Subjects';
import Papers from './components/Papers';
import PaperData from './components/PaperData';
import PaperParts from './components/PaperParts';
import ViewPaperPart from './components/ViewPaperPart';
import ViewPaperPartVideo from './components/ViewPaperPartVideo';
import ViewPaperPartAnswerSheet from './components/ViewPaperPartAnswerSheet';
import PaperAnswerSheets from './components/PaperAnswerSheets';
import ViewAnswerSheet from './components/ViewAnswerSheet';
import PaperVideoList from './components/PaperVideoList';
import ViewVideo from './components/ViewVideo';
import UserProfile from './components/userProfile/UserProfile';
import MySubscriptions from './components/MySubscriptions';
import AuthenticationFailed from './components/AuthenticationFailed';
import SubscriptionResult from './components/SubscriptionMessage';
import PapersSearchResult from './components/PapersSearchResult';
import PrivacyPolicy from './components/PrivacyPolicy';

function App() {
  return (
    <>
      <Router>
        <Navbar />
        <Switch>
          <Route path='/' exact component={Home} />
          <Route path='/olevel' component={Olevel} />
          <Route path='/alevel' component={Alevel} />
          <Route path='/sign-up' component={Register} />
          <Route path='/schoolexam' component={SchoolExam} />
          <Route path='/professional' component={Professional} />
          <Route path='/login' component={Login} />
          <Route path='/reset-password' component={ResetPassword} />
          <Route path='/profile' component={UserProfile} />
          <Route path='/my-subscriptions' component={MySubscriptions} />
          <Route path='/community' component={Community} />
          <Route path='/display-book' component={Bookalivedisplay} />
          <Route path='/search-papers' component={SearchPaper} />
          <Route path='/about' component={AboutUs} />
          <Route path='/contact' component={ContactUs} />
          <Route path='/sub-categories' component={ParentSubCategories} />
          <Route path='/sub-category' component={SubCategories} />
          <Route path='/subjects' component={Subjects} />
          <Route path='/papers' component={Papers} />
          <Route path='/paper-data' component={PaperData} />
          <Route path='/paper-parts' component={PaperParts} />
          <Route path='/view-paper-part' component={ViewPaperPart} />
          <Route path='/view-paper-video' component={ViewPaperPartVideo} />
          <Route path='/view-paper-part-answer-sheet' component={ViewPaperPartAnswerSheet} />
          <Route path='/view-paper-answer-sheets' component={PaperAnswerSheets} />
          <Route path='/view-answer-sheet' component={ViewAnswerSheet} />
          <Route path='/view-paper-videos' component={PaperVideoList} /> {/* View videos of paper */}
          <Route path='/view-video' component={ViewVideo} /> {/* View videos of paper by video id */}
          <Route path='/authentication-failed' component={AuthenticationFailed} /> {/* show message if tried to view any vid, answersheet or paper without logged in*/}
          <Route path='/subscription-result' component={SubscriptionResult} /> 
          <Route path='/paper-result' component={PapersSearchResult} /> 
          <Route path='/policy' component={PrivacyPolicy} /> 
          

        </Switch>
        <Footer />
      </Router>
    </>
  );
}

export default App;