import React, { useState } from 'react';
import "./ContactUs.css";
import axios from 'axios';
import HOST_URL from './config';
import { Oval } from 'react-loader-spinner'
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';

const ContactUs = () => {
  const [isLoading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  const onContactUsForm = (event) => {
    event.preventDefault();

    let name = document.getElementById('name').value
    let email = document.getElementById('email').value
    let subject = document.getElementById('subject').value
    let message = document.getElementById('message').value

    const token = localStorage.getItem('token');
    setLoading(true);

    axios.post(`${HOST_URL}api/past-paper/contact-us`, {
      name: name,
      email: email,
      subject: subject,
      message: message
    }, {
      headers: { 'Authorization': `Bearer ${token}` },
    })
      .then(response => {
        // console.log(response)
        if (response.status == 200) {
          setAlertMessage({ type: "success", text: 'Thank you for contacting us you will get a response soon ...!', color: 'green' });
          setTimeout(() => {
            setAlertMessage(null);
          }, 5000);
          alert('Thank you for contacting us you will get a response soon ...')
          setLoading(false);
        } else {
          setAlertMessage({ type: "error", text: 'Form submission failed.', color: 'red' });
          setTimeout(() => {
            setAlertMessage(null);
          }, 5000);
          setLoading(false);
        }
      })
      .catch(error => {
        if (error.response.status == 401) {
          localStorage.setItem('isLoggedIn', JSON.stringify(false));
        }
        console.log(error);
        setLoading(false);
        setAlertMessage({ type: "error", text: 'Form submission failed.', color: 'red' });
        setTimeout(() => {
          setAlertMessage(null);
        }, 5000);
      });
    // setErrors(Validation(values))
  };

  return (
    <div className="contact">
      <h1 style={{
        justifyContent: "center",
        textAlign: "center",
        color: "red"
      }}>Contact Us</h1>
      <div className="first-row">
        <div className="box-contact-us">
          <div className="icon">
            <i className="fas fa-envelope"></i>
          </div>
          <div className="text">
            <h6>Have any questions?</h6>
            <p>support@passpapers.lk</p>
          </div>
        </div>

        {/* <div className="box-contact-us">
          <div className="icon">
            <i className="fas fa-phone"></i>
          </div>
          <div className="text">
            <h6>Call us</h6>
            <p>+-----------</p>
          </div>
        </div> */}

        {/* <div className="box-contact-us">
          <div className="icon">
            <i className="fas fa-fax"></i>
          </div>
          <div className="text">
            <h6>Fax</h6>
            <p>+-----------</p>
          </div>
        </div> */}
      </div>

      <div className="contact-section">
        <div className="contact-info">
          <h2>Write a message</h2>
          <p>
            At PassPaper.lk, we are dedicated to providing our users with the best possible experience. If you have any questions or concerns regarding our platform, our team is always available to assist you. We value your feedback and suggestions. If you have any ideas or suggestions on how we can improve our platform, please do not hesitate to reach out to us. We are always looking for ways to enhance our services and resources to better serve our user.
          </p>
          {/* <p>Lorem ipsum dolor sit amet.</p> */}
        </div>


        <div className="contact-form">
          {
            alertMessage && (
              <div style={{ color: alertMessage.color }}>
                {/* {alertMessage.text} */}
                <Stack sx={{ width: '100%' }} spacing={2}>
                  <Alert severity={alertMessage.type}>{alertMessage.text}</Alert>
                </Stack>
              </div>
            )

          }

          <form className="contact" onSubmit={onContactUsForm}>
            <input type="text" name="name" id="name" className="text-box" placeholder="Name" />
            <input type="email" name="email" id="email" className="text-box" placeholder="E-mail" />
            <input type="text" name="subject" id="subject" className="text-box" placeholder="Subject" />
            <textarea name="message" id="message" rows="5" placeholder="Your message" required></textarea>

            {isLoading ? (
              <div className="spinner" style={{
                marginBottom: "20px"
              }}>
                <Oval
                  height={40}
                  width={40}
                  color="#4fa94d"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                  ariaLabel='oval-loading'
                  secondaryColor="#4fa94d"
                  strokeWidth={2}
                  strokeWidthSecondary={2}

                />
              </div>
            ) : (
              <input type="submit" name="submit" className="send-btn" id="contact_btn" value="Send" />
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
