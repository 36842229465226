import React, { useEffect, useState } from 'react';
import axios from 'axios';
import HOST_URL from './config';

function LoginStatus() {
    const [isLoggedIn, setIsLoggedIn] = useState(null);

    useEffect(() => {
        checkAuth().then(res => {
            // check status for response and set data accordingly
            if (res) {
                setIsLoggedIn(res.data.isLoggedIn)
                localStorage.setItem('isLoggedIn', JSON.stringify(res.data.isLoggedIn));
            }
            // else {
            //     setIsLoggedIn(false)
            //     localStorage.setItem('isLoggedIn', JSON.stringify(false));
            // }
            // log the data
        })
        // const token = sessionStorage.getItem('passpaperToken');
    }, []);

    const checkAuth = async () => {
        try {
            const token = localStorage.getItem('token');
            // const user = localStorage.getItem('user');
            const response = await axios.get(`${HOST_URL}api/check-login`, {
                headers: { 'Authorization': `Bearer ${token}` },
            });
            // return the whole response object instead of only the data.
            // this helps in error handling in the component
            return response;
        }
        catch (error) {
            if (error.response.status === 401) {
                localStorage.setItem('isLoggedIn', JSON.stringify(false));
            }
            // if(error)
            // console.log("error")
            // console.log(error.response.status)
            // console.log("error")
        }
    }

    // function checkAuth() {
    //     const token = localStorage.getItem('token');
    //     const user = localStorage.getItem('user');
    //     if (token != "null" && user != null) {
    //         axios.get(`${HOST_URL}api/check-login`, {
    //             headers: { 'Authorization': `Bearer ${token}` },
    //         })
    //         // .then(response => {
    //         //     console.log(response.data)
    //         //     localStorage.setItem('isLoggedIn', JSON.stringify(response.data.isLoggedIn));
    //         //     setIsLoggedIn(response.data.isLoggedIn);

    //         //     // return response.data.isLoggedIn

    //         // })
    //         // .catch(
    //         //     error => console.log(error),
    //         //     localStorage.setItem('isLoggedIn', JSON.stringify(false))
    //         // );
    //     } else {
    //         setIsLoggedIn(false);
    //     }
    // }
    return isLoggedIn
}

export default LoginStatus;