import React, { useState, useEffect } from "react";
import "./userProfile.css";
import axios from 'axios';
import HOST_URL from '../config';
import { Oval } from 'react-loader-spinner'
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';

const UserProfile = () => {

  const [Data, setData] = useState({})
  const [Name, setName] = useState('')
  const [isLoading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  // const [responseSuccessStatus, setResponseSuccessStatus] = useState(false);

  useEffect(() => {
    let isMounted = true;

    getUserDetails().then(res => {
      if (isMounted) {
        if (res) {
          setData(res.data);
          setName(res.data.name)
          // console.log(typeof (res.data))
        }
      }
    });
    return () => {
      isMounted = false;
    };
  }, []);

  async function getUserDetails() {
    const token = localStorage.getItem('token');
    const response = await axios.get(`${HOST_URL}api/show-user-profile`, {
      headers: { 'Authorization': `Bearer ${token}` },
    })
    return response
  }

  function handleOnEditProfile(event) {
    // edit-user-profile
    event.preventDefault();

    let name = document.getElementById('name').value
    let nic = document.getElementById('nic').value
    let dob = document.getElementById('dob').value
    let email = document.getElementById('email').value
    let phone = document.getElementById('phone').value
    let address = document.getElementById('address').value
    // setName(name)

    const token = localStorage.getItem('token');
    setLoading(true);

    axios.post(`${HOST_URL}api/edit-user-profile`, {
      name: name,
      email: email,
      nic: nic,
      dob: dob,
      phone: phone,
      address: address,
    }, {
      headers: { 'Authorization': `Bearer ${token}` },
    })
      .then(response => {
        // console.log(response)
        if (response.status == 200) {
          setAlertMessage({ type: "success", text: 'Changes Saved Successfully ...!', color: 'green' });
          setName(name)
          setLoading(false);
          setTimeout(() => {
            setAlertMessage(null);
          }, 5000);
        } else {
          // setAlertMessage('Failed to save Changes.');
          setAlertMessage({ type: "error", text: 'Failed to save Changes.', color: 'red' });
          // setResponseSuccessStatus(false)
          setLoading(false);
          setTimeout(() => {
            setAlertMessage(null);
          }, 5000);
        }
      })
      .catch(error => {
        if (error.response.status == 401) {
          localStorage.setItem('isLoggedIn', JSON.stringify(false));
        }
        setAlertMessage({ type: "error", text: 'Failed to save Changes.', color: 'red' });

        console.log(error);
        setLoading(false);
        setTimeout(() => {
          setAlertMessage(null);
        }, 5000);
        // alert("Failed to Login")
      });
  }

  // export default class UserProfile extends Component {
  // render() {
  return (
    <div className="main__userprofile">
      <div className="profile__card">
        <div className="">
          {/* <h1>{Name}</h1> */}
          <h1>My Account</h1>
        </div>
      </div>
      <div className="profile__card">
        {
          alertMessage && (
            <p style={{ color: alertMessage.color }}>
              {/* {alertMessage.text} */}
              <Stack sx={{ width: '100%' }} spacing={2}>
                <Alert severity={alertMessage.type}>{alertMessage.text}</Alert>
              </Stack>
            </p>
          )
        }
        <form className="" onSubmit={handleOnEditProfile}>
          <div>
            <input type="text" name="name" id="name" className="profile-text-box" defaultValue={Data.name} placeholder="Name" />
            <input type="text" name="nic" id="nic" className="profile-text-box" defaultValue={Data.nic} placeholder="NIC" />
            <input type="date" name="dob" id="dob" className="profile-text-box" defaultValue={Data.date_of_birth} placeholder="Date of Birth" style={{
              // color: "white",
              cursor: "pointer"
            }}/>
          </div>
          <div>
            <input type="email" name="email" id="email" defaultValue={Data.email} className="profile-text-box" placeholder="E-mail" />
            <input type="text" name="phone" id="phone" defaultValue={Data.phone_number} className="profile-text-box" placeholder="Phone Number" />
            <input type="text" name="address" id="address" defaultValue={Data.address} className="profile-text-box" placeholder="Address" />
          </div>

          {isLoading ? (
            <div className="spinner" style={{
              marginBottom: "20px"
            }}>
              <Oval
                height={40}
                width={40}
                color="#4fa94d"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                ariaLabel='oval-loading'
                secondaryColor="#4fa94d"
                strokeWidth={2}
                strokeWidthSecondary={2}

              />
            </div>
          ) : (
            <div>
              <input type="submit" name="submit" className="send-btn" id="contact_btn" value="Save" />
            </div>
          )}
        </form>
      </div>
    </div>
  );
  // }
}

export default UserProfile;
