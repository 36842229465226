import React, { useState, useEffect } from 'react';
// import { Button1 } from './Button1';
import { Link } from 'react-router-dom';
import './Navbar.css';
import axios from 'axios';
import LoginStatus from './UserAuth';
import { useHistory } from 'react-router-dom';
import HOST_URL from './config';
import logo from '../logo_web.png';

function Navbar() {
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const history = useHistory();

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const isLoggedInUserAuth = LoginStatus();
  // console.log("isLoggedInUserAuth")
  // console.log(isLoggedInUserAuth)
  // console.log("isLoggedInUserAuth")
  // useEffect(() => {
  //   document.title = "Pass Papers";
  // }, []);
  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  useEffect(() => {
    showButton();
  }, []);

  useEffect(() => {
    setIsLoggedIn(isLoggedInUserAuth)
  }, [isLoggedInUserAuth]);

  function handleLogout() {
    // logout
    const token = localStorage.getItem('token');
    // const user = localStorage.getItem('user');
    if (token) {
      // console.log(token)

      axios.post(`${HOST_URL}api/logout`, {}, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      })
        .then(response => {
          // Save token to local storage
          // alert("hri logout wuna")

          if (response.status === 200) {
            localStorage.setItem('isLoggedIn', JSON.stringify(false));
            localStorage.setItem('token', null);
            setIsLoggedIn(false)
            history.push('/login');
            window.location.reload('/login')

          } else {
            alert("Error in Logout")
          }
          // localStorage.setItem('token', );
        })
        .catch(error => {
          localStorage.setItem('token', null);
          localStorage.setItem('isLoggedIn', JSON.stringify(false));
          history.push('/login');
          window.location.reload('/login')
          console.log(error);
        });
    }
  }

  window.addEventListener('resize', showButton);

  return (

    <nav className='navbar'>
      <div className='navbar-container'>
        <Link to='/' className='navbar-logo' onClick={closeMobileMenu} style={{
          width: "12%"
        }}>
          {/* Past Papers */}
          {/* <div
            style={{
              width: "60%"
            }}
          > */}
          <img src={logo} alt="Logo"
            style={{
              width: "100%"
            }}
          />
          {/* </div> */}
        </Link>

        <div className='menu-icon' onClick={handleClick}>
          <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
        </div>
        <ul className={click ? 'nav-menu active' : 'nav-menu'}>
          <li className='nav-item'>
            <a href='/' className='nav-links' onClick={closeMobileMenu}>
              Home
            </a>
          </li>

          {/* <li className='nav-item'>
            <a href='/' className='nav-links' onClick={closeMobileMenu}>
              Past Papers
            </a>
          </li> */}

          {/* <li className='nav-item'>
            <a href='/' className='nav-links' onClick={closeMobileMenu}>
              Paper Discussion
            </a>
            <ul className='semma'>
              <li><a href='#' > Instructors </a></li>
              <li><a href='#' > Book A Paper Discussion </a></li>
              <li><a href='#' > Chat with a instructor </a></li>
              <li><a href='#' > Ask Questions (Chat) </a></li>
              <li><a href='#' > Search for instructor </a></li>
            </ul>
          </li> */}

          <li className='nav-item'>
            <a
              href='/about'
              className='nav-links'
              onClick={closeMobileMenu}
            >
              About
            </a>
          </li>

          {/* <li className='nav-item'>
            <a
              href='/policy'
              className='nav-links'
              onClick={closeMobileMenu}
            >
              Privacy Policy
            </a>
          </li> */}

          {/* <li className='nav-item'>
            <a
              href='/'
              className='nav-links'
              onClick={closeMobileMenu}
            >
              Services
            </a>
          </li> */}

          <li className='nav-item'>
            <a
              href='/contact'
              className='nav-links'
              onClick={closeMobileMenu}
            >
              Contact Us
            </a>
          </li>

          {/* <li className='nav-item' id='login_btn'>
            <a
              href='/login'
              className='nav-links'
              onClick={closeMobileMenu}
            >
              Login
            </a>
          </li>

          <li id='signup_btn'>
            <a
              href='/sign-up'
              className='nav-links-mobile'
              onClick={closeMobileMenu}
            >
              Sign Up
            </a>
          </li> */}

          {
            (isLoggedIn !== true) ?
              null
              :
              <li className='nav-item' id='login_btn'>
                <a
                  href='/profile'
                  className='nav-links'
                  onClick={closeMobileMenu}
                >
                  Profile
                </a>
              </li>
          }

          {
            (isLoggedIn !== true) ?
              null
              :
              <li className='nav-item' id='login_btn'>
                <a
                  href='/my-subscriptions'
                  className='nav-links'
                  onClick={closeMobileMenu}
                >
                  My Subscriptions
                </a>
              </li>
          }

          {
            (isLoggedIn === true) ?
              <li className='nav-item' id='login_btn'>
                <a>
                  <button className='nav-links' onClick={handleLogout} style={{
                    border: "none",
                    background: "transparent",
                    cursor: "pointer"
                  }}>
                    Logout
                  </button>

                </a>
              </li>
              :
              <li className='nav-item'>
                <a
                  href='/sign-up'
                  className='nav-links'
                  onClick={closeMobileMenu}
                >
                  SIGN UP
                </a>
              </li>
            // button && <Button1 buttonStyle='btn--outline'>SIGN UP</Button1>
          }

          {
            (isLoggedIn === true) ?
              null
              :
              <li className='nav-item' id='login_btn'>
                <a
                  href='/login'
                  className='nav-links'
                  onClick={closeMobileMenu}
                >
                  Login
                </a>
              </li>
          }

        </ul>

      </div>
    </nav>
  );
}

export default Navbar;
