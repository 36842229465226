import React, { useState, useEffect } from 'react';
import './Register.css'
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import HOST_URL from '../config';
import { Oval } from 'react-loader-spinner'
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
// import LoginStatus from '../UserAuth';
const Login = () => {
  // const [isLoggedIn, setIsLoggedIn] = useState(null);
  // const isLoggedIn = LoginStatus();
  const history = useHistory();
  const [isLoading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  const handleFormSubmit = (event) => {
    event.preventDefault();
    setLoading(true);

    let email = document.getElementById('email').value
    let password = document.getElementById('password').value

    axios.post(`${HOST_URL}api/login`, {
      email: email,
      password: password
    })
      .then(response => {
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('user', response.data.user_id);

        localStorage.setItem('isLoggedIn', JSON.stringify(true));

        if (response.status == 201) {
          setAlertMessage({ type: "success", text: 'Logged in Successfully ...!', color: 'green' });
          
          alert("Logged in Successfully")
          setLoading(false);

          history.push('/');
          window.location.reload('/')

        } else {
          setLoading(false);
          setAlertMessage({ type: "success", text: 'Failed to Login.', color: 'red' });
          alert("Failed to Login")
          window.location.reload('/login')
        }
      })
      .catch(error => {
        console.log(error);
        if (error.response.status === 401) {
          setLoading(false);
          setAlertMessage({  type: "error", text: 'Invalid Username or Password.', color: 'red' });
          alert("Invalid Username or Password")
        }
        else {
          setLoading(false);
          alert("Failed to Login")
        }
      });
    // setErrors(Validation(values))
  };

  useEffect(() => {
    let isLoggedInStatus = localStorage.getItem('isLoggedIn');
    // setIsLoggedIn(isLoggedInStatus)
    if (history.pathname = '/login' && isLoggedInStatus === "true") {
      history.push('/')
      window.location.reload('/')
    } else {
      history.push('/login')
      // window.location.reload('/login')
    }
  }, []);
  return (
    <div className='containerLogin'>
       {
          alertMessage && (
            <p style={{ color: alertMessage.color }}>
              {/* {alertMessage.text} */}
              <Stack sx={{ width: '100%' }} spacing={2}>
                <Alert severity={alertMessage.type}>{alertMessage.text}</Alert>
              </Stack>
            </p>
          )
        }
      <form onSubmit={handleFormSubmit}>
        <h1 className='heading-sign'>Log in Form</h1>
        <div className='ui devider'></div>
        <div className='ui form'>

          <div className="field">
            <label htmlFor="Email"></label>
            <input type="email" name='email' id='email' placeholder='Email/Username' />
          </div>
          <div className="field">
            <label htmlFor="Password"></label>
            <input type="password" name='password' id='password' placeholder='Password' />
          </div>

          <div className="ui checkbox">
            <input type="checkbox" name="example" />
            <label>Remember me</label>
          </div>
          <a href="/reset-password">Forgot Password?</a>
          <div className='loginButton-2'>
            {isLoading ? (
              <div className="spinner" style={{
                marginBottom: "20px",
                display: "flex",
                justifyContent: "center"
              }}>
                <Oval
                  height={40}
                  width={40}
                  color="#4fa94d"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                  ariaLabel='oval-loading'
                  secondaryColor="#4fa94d"
                  strokeWidth={2}
                  strokeWidthSecondary={2}

                />
              </div>
            ) : (
              <div>
                <button className="fluid ui blue basic button">Log in</button>
              </div>
            )}
          </div>
          {/* <div className='or-para'>
            or
          </div> */}
          {/* <div className='twowayLogin'>
            <button className="ui facebook button">
              <i className="facebook icon"></i>
              Facebook
            </button>
            <button className="ui google button">
              <i className="google icon"></i>
              Google
            </button>
          </div> */}

          <div className="terms">
            <p>Don't have an account? <a href="/sign-up"> Create an account </a>

            </p>
          </div>

        </div>

      </form>
    </div>
  )
}

export default Login