import React from "react";
// import "./ContactUs.css";
// import { useHistory } from "react-router-dom";
import SubCategoriesCardsWrap from './CardsWrap/SubCategoriesCardsWrap'
import { useLocation } from 'react-router-dom';
const SubCategories = () => {
  // const history = useHistory();
  // const state = history.location.state || {};
  // let categoryId = state.categoryId;
  const location = useLocation();
  const sub_category_id = location.state.sub_category_id;
  const sub_category = location.state.sub_category;

  return (
    <div style={{
      marginBottom: "100px"
    }}>
      <div style={{
        textAlign: "center"
      }}>
        <h1 className='hero-h1'>Sub Categories of {sub_category}</h1>
      </div>
      <div
        style={{
          marginTop: "100px"
        }}>
        <SubCategoriesCardsWrap
          sub_category_id={sub_category_id}
        />
      </div>

    </div>
  );
};

export default SubCategories;
