import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import './CardsWrap.css'
import axios from 'axios';
import HOST_URL from '../config';

export default function PaperDataCardsWrap({ paper_id, paper_name, image, year, medium }) {
    return (

        <div className='cards-wrap'>
            <div className='cards-wrap2'>
                <div key={paper_id} className="card">
                    <Link
                        to={{
                            pathname: "/paper-parts",
                            state: { paper: paper_id, paper_name: paper_name, year: year, medium: medium }
                        }}
                        className="video-link">
                        <img src={`${HOST_URL}storage/` + image} alt="Past Paper" className="image" />
                        <div className="content">
                            <div className="label-wrap">
                            </div>
                            <div className="sub-content">
                                <p className="title">View Parts of {paper_name} {year}</p>
                                <div className="labels">
                                    <div className='label-wrap'>
                                        {/* <p className="label light-blue">{item.parts.length + " Parts"}</p> */}
                                    </div>
                                    <div className='label-wrap'>
                                        {/* <p className="label royal-blue">{item.answer_sheets.length + " Answer sheets"}</p> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Link>
                </div>

                <div key={paper_id} className="card">
                    <Link
                        to={{
                            pathname: "/view-paper-answer-sheets",
                            state: { paper: paper_id, paper_name: paper_name, year: year, medium: medium }
                        }}
                        className="video-link">
                        <img src={`${HOST_URL}storage/` + image} alt="Past Paper" className="image" />
                        <div className="content">
                            <div className="label-wrap">
                            </div>
                            <div className="sub-content">
                                <p className="title">View Answer Sheets of {paper_name} {year}</p>
                                <div className="labels">
                                    <div className='label-wrap'>
                                        {/* <p className="label light-blue">{item.parts.length + " Parts"}</p> */}
                                    </div>
                                    <div className='label-wrap'>
                                        {/* <p className="label royal-blue">{item.answer_sheets.length + " Answer sheets"}</p> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Link>
                </div>

                <div key={paper_id} className="card">
                    <Link
                        to={{
                            pathname: "/view-paper-videos",
                            state: { paper: paper_id, paper_name: paper_name, year: year, medium: medium }
                        }}
                        className="video-link">
                        <img src={`${HOST_URL}storage/` + image} alt="Past Paper" className="image" />
                        <div className="content">
                            <div className="label-wrap">
                            </div>
                            <div className="sub-content">
                                <p className="title">View Videos of {paper_name} {year}</p>
                                <div className="labels">
                                    <div className='label-wrap'>
                                        {/* <p className="label light-blue">{item.parts.length + " Parts"}</p> */}
                                    </div>
                                    <div className='label-wrap'>
                                        {/* <p className="label royal-blue">{item.answer_sheets.length + " Answer sheets"}</p> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Link>
                </div>

            </div>
        </div>
    )


}
