import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import './CardsWrap.css'
import { useHistory } from "react-router-dom"
import HOST_URL from '../config';

export default function CategoriesCardsWrap({ data, medium }) {
    const [Data, setData] = useState([])
    const history = useHistory();

    useEffect(() => {
        let isMounted = true;

        if (Array.isArray(data) && isMounted) setData(data)

        return () => {
            isMounted = false
        };
    }, [data])

    return (
            <div className='cards-wrap'>
                {
                    Data.map(item => {
                        const filteredPapers = item.papers.filter(paper => paper.medium_id == medium);

                        return (
                            <div key={item.id}>
                                <div className="card">
                                    <Link
                                        to={{
                                            pathname: "/sub-categories",
                                            state: { category_id: item.id, category: item.category, medium: medium }// your data array of objects
                                        }}
                                        // href='/sub-categories'
                                        // onClick={handleOnCategoryClick(item.id)}
                                        className="video-link"
                                    >
                                        <img src={`${HOST_URL}storage/` + item.image} alt="Past Paper" className="image" />

                                        <div className="content">
                                            <div className="label-wrap">
                                                {/* <Link to="" className="video-link"><p className="label curved">Video</p></Link> */}
                                                {/* <img src="/images/video-circle.png" alt="Video Icon" className="video-icon" /> */}
                                            </div>

                                            <div className="sub-content">
                                                <p className="title">{item.category}</p>

                                                <div className="labels">
                                                    <div className="label-wrap">
                                                        <p className="label light-blue">{
                                                        filteredPapers.length
                                                        } Past papers</p>
                                                    </div>
                                                    {/* <p className="label royal-blue">Answer sheet</p> */}
                                                    <div className="label-wrap">
                                                        {/* <Link to="" className="video-link"> <p className="label purple">Paper discussion</p></Link>
                                                    <img src="/images/video-circle.png" alt="Video Icon" className="video-icon small" /> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        )
                    })
                }
            </div>

    )
}