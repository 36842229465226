import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import './CardsWrap.css'
import axios from 'axios';
import HOST_URL from '../config';

export default function CardsWrap({ paper }) {
    const [Data, setData] = useState([])

    useEffect(() => {
        let isMounted = true;
        getPaperParts(paper)
        // if(Array.isArray(data) && isMounted) setData(data)

        return () => {
            isMounted = false
        };
    }, [paper])



    async function getPaperParts(medium) {
        // API request
        // await axios.get("http://127.0.0.1:8000/api/past-paper/category/" + category + "/medium/" + medium + "").then(response => {
            // setData(response.data);
        // })
    }

    return (
        <div className='cards-wrap'>
            {
                Data.map(item => {
                    return (
                        <div className="card">
                            <Link to="/sub-categories"
                                href='/sub-categories'
                                className="">

                                <img src={`${HOST_URL}storage/` + item.image} alt="Past Paper" className="image" />

                                <div className="content">
                                    <div className="label-wrap">
                                        <Link to="" className="video-link"><p className="label curved">Video</p></Link>
                                        <img src="/images/video-circle.png" alt="Video Icon" className="video-icon" />
                                    </div>

                                    <div className="sub-content">
                                        <p className="title">{item.paper_name} {item.year}</p>

                                        <div className="labels">
                                            <p className="label light-blue">{item.parts.length + " Parts"}</p>
                                            <p className="label royal-blue">Answer sheet</p>
                                            <div className="label-wrap">
                                                <Link to="" className="video-link"> <p className="label purple">Paper discussion</p></Link>
                                                <img src="/images/video-circle.png" alt="Video Icon" className="video-icon small" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    )
                })
            }
        </div>
    )
}