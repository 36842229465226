import React from 'react'
import './AboutUs.css'
import aboutus from '../about-img.jpeg';
const AboutUs = () => {
  return (
    <div>
      <h1 style={{
        justifyContent: "center",
        textAlign: "center",
        color: "black"
      }}>About Us</h1>
      <section className='about'>
        <div className="main-about">
          <div className='left-side'>
            <div className='about-text'>
              <h1>Why Choose Us</h1>
              <p>PassPaper.lk is a comprehensive online platform that provides a wide range of past papers, model answer sheets, and paper discussion videos for students of all levels. The platform offers resources for school students, university students, professionals, and anyone preparing for public examinations. PassPaper.lk is available as a website and mobile application, making it convenient for students to access the platform and its resources from anywhere.

                The PassPaper.lk website and mobile applications provide access to a vast collection of past papers, model answer sheets, and paper discussion videos for a range of different exams, including O/L, A/L, university exams, and professional qualifications such as ACCA, CIMA, and CIM. The platform also provides access to paper discussion sessions conducted by experienced teachers and experts in the relevant fields, which can help students understand difficult concepts and improve their exam technique.

                To access the full range of resources on the PassPaper.lk platform, users are required to subscribe to a monthly subscription plan. The subscription plan provides access to model answer sheets, paper discussion videos, and other premium services. However, public examination papers are available for free to all users.

                The PassPaper.lk platform also offers individual paper discussion sessions with instructors or lecturers, which are charged separately. These sessions are designed to provide personalized assistance to students who may require additional support or guidance with their exam preparation.

                In conclusion, PassPaper.lk is an excellent resource for students who are looking to improve their exam performance and achieve their academic goals. The platform provides access to a vast collection of past papers, model answer sheets, and paper discussion videos for various exams, making it a valuable tool for students of all levels. With its user-friendly interface and convenient mobile application, PassPaper.lk is a must-have tool for anyone looking to excel in their academic pursuits.

                Whether you are preparing for O/L, A/L, university exams, or professional qualifications such as ACCA, CIMA, or CIM, and Public Examinations, PassPaper.lk has everything you need to succeed. With a user-friendly interface, easy navigation, and a vast database of resources, PassPaper.lk is an excellent tool for anyone looking to improve their exam performance and achieve their academic goals.
              </p>
            </div>
          </div>
          <div className='right-side'>
            {/* <div> */}
            <img className='about-img' src={aboutus} alt="" />
            {/* </div> */}

            <div className='text2-h1-container'>
              <h1>
                Mobile Platform :
              </h1>
            </div>
            <div className='about-text2'>
              <p>
                PassPaper.lk offers a convenient and user-friendly mobile application that allows students to access a vast database of past papers, model answer sheets, and paper discussion videos on their mobile devices. The app is available for download on both Android and iOS platforms, and it offers a seamless experience for users to access and download past papers for a range of different examinations.

                With the PassPaper mobile app, students can easily find past papers for their desired examination, including O/L, A/L, university exams, and professional qualifications. The app also provides model answer sheets that can help students to improve their exam technique and prepare more effectively. Additionally, students can watch paper discussion videos conducted by experienced teachers and experts in their respective fields, which provide valuable insights and tips on how to approach different types of questions.

                The PassPaper mobile app features a simple and intuitive interface, making it easy for users to navigate and find the resources they need. The app also provides regular updates with new past papers, model answer sheets, and paper discussion videos, ensuring that users have access to the most up-to-date and relevant materials.

                Furthermore, the PassPaper mobile app provides a range of useful features that enhance the user experience, such as the ability to save and bookmark past papers, model answer sheets, and paper discussion videos for offline viewing. Users can also customize their search preferences, filter past papers by subject and year, and receive notifications for new uploads and updates.

                In conclusion, the PassPaper mobile app is an excellent resource for students of all levels who are looking to improve their exam performance and achieve their academic goals. With its comprehensive database of past papers, model answer sheets, and paper discussion videos, combined with its user-friendly interface and useful features, the PassPaper mobile app is a must-have tool for any student. So, don't hesitate and download the PassPaper mobile app today!
              </p>
            </div>

          </div>
        </div>
      </section >
    </div>
  )
}

export default AboutUs
