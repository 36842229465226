import React from 'react';
import './PrivacyPolicy.css';
import aboutus from '../about-img.jpeg';
const PrivacyPolicy = () => {
  return (
    <div>
      <div className="parentHeader">Privacy Policy for Pass Papers</div>
      <section className="privacy-policy">
        {/* <div className="privacy-intro-container"> */}
        <div className="privacy-intro">
          At Pass Papers, we are committed to protecting the privacy and
          confidentiality of your personal information. This Privacy Policy
          explains how we collect, use, disclose, and safeguard your data when
          you use our website and mobile app for accessing past exam papers,
          answer sheets, and paper discussion videos. By using Pass Papers, you
          agree to the practices described in this Privacy Policy.
        </div>
        {/* </div> */}

        <div className="main-privacy-policy">
          <div className="left-side">
            <div className="privacy-policy-text">
              <div className="seperations">
                <div className="heading">1. Privacy Policy for Pass Papers</div>

                <div className="underTheHeading">
                  We collect two types of information:
                </div>
                <div>
                  <div className="subheading">a. Personal Information:</div>{' '}
                  <div className="underTheSubHeading">
                    This is information that can identify you directly, such as
                    your name, email address, and any other information you
                    choose to provide when creating an account or using our
                    services.
                  </div>
                  <div className="subheading">b. Non-Personal Information:</div>{' '}
                  <div className="underTheSubHeading">
                    This is information that cannot be used to identify you
                    personally, such as your device information, IP address, and
                    usage statistics.
                  </div>
                </div>
              </div>
              <div className="seperations">
                <div className="heading">2. How We Collect Information</div>
                <div className="underTheHeading">
                  We collect two types of information:
                </div>
                <div>
                  <div>
                    <div className="subheading">
                      a. Information You Provide:
                    </div>{' '}
                    <div className="underTheSubHeading">
                      You may provide personal information when creating an
                      account, submitting a contact form, or interacting with
                      our services
                    </div>
                    <div className="subheading">
                      b. Automatically Collected Information:
                    </div>{' '}
                    <div className="underTheSubHeading">
                      We may collect non-personal information through cookies,
                      web beacons, and other tracking technologies when you
                      visit our website or use our mobile app.
                    </div>
                  </div>
                </div>
              </div>
              <div className="seperations">
                <div className="heading">3. How We Use Your Information</div>
                <div className="underTheHeading">
                  We use your information for the following purposes:
                </div>
                <div>
                  <div className="subheading">a. To Provide Services: </div>
                  <div className="underTheSubHeading">
                    We use your personal information to provide you with access
                    to past exam papers, answer sheets, and paper discussion
                    videos.
                  </div>

                  <div className="subheading">b. Communication: </div>
                  <div className="underTheSubHeading">
                    We may use your email address to send you updates,
                    announcements, and important information related to our
                    services.
                  </div>

                  <div className="subheading">c. Improving Our Services: </div>
                  <div className="underTheSubHeading">
                    We use non-personal information to analyze user behavior,
                    trends, and preferences to improve our website and app.
                  </div>

                  <div className="subheading">d. Legal Obligations: </div>
                  <div className="underTheSubHeading">
                    We may use your information to comply with applicable laws
                    and regulations.
                  </div>
                </div>
              </div>
              <div className="seperations">
                <div className="heading">4. Sharing Your Information</div>

                <div className="underTheHeading">
                  We do not sell or rent your personal information to third
                  parties. However, we may share your information under the
                  following circumstances:
                </div>
                <div>
                  <div className="subheading">
                    a. With Third-Party Service Providers:
                  </div>{' '}
                  <div className="underTheSubHeading">
                    We may share information with trusted service providers who
                    help us operate, maintain, and improve our services.
                  </div>
                  <div className="subheading">b. Legal Compliance:</div>{' '}
                  <div className="underTheSubHeading">
                    We may disclose your information if required by law or in
                    response to a valid legal request, such as a court order.
                  </div>
                  <div className="subheading">c. Business Transfers:</div>{' '}
                  <div className="underTheSubHeading">
                    If Pass Papers is involved in a merger, acquisition, or sale
                    of assets, your information may be transferred to the new
                    owner.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="right-side">
            <div className="text2-h1-container"></div>
            <div className="privacy-policy-text2">
              <div className="seperations">
                <div className="heading">5. Security</div>

                <div className="underTheHeading">
                  We take reasonable measures to protect your information
                  against unauthorized access and disclosure. However, no online
                  platform can guarantee absolute security, and you acknowledge
                  the inherent risks of sharing information online.
                </div>
              </div>
              <div className="seperations">
                <div className="heading">6. Your Choices</div>

                <div className="underTheHeading">
                  We collect two types of information:
                </div>
                <div>
                  <div className="subheading">a. Account Information:</div>
                  <div className="underTheSubHeading">
                    You can review and update your account information by
                    logging into your account on our website or app.
                  </div>
                  <div className="subheading">b. Cookies and Tracking:</div>
                  <div className="underTheSubHeading">
                    You can adjust your browser settings to refuse cookies and
                    other tracking technologies.
                  </div>
                  <div className="subheading">c. Unsubscribe:</div>
                  <div className="underTheSubHeading">
                    You can opt-out of receiving email communications from us by
                    following the instructions in the emails.
                  </div>
                </div>
              </div>
              <div className="seperations">
                <div className="heading">7. Children's Privacy</div>
                <div className="underTheHeading">
                  Pass Papers does not knowingly collect personal information
                  from children under the age of 13. If you believe we have
                  inadvertently collected such information, please contact us to
                  have it deleted.
                </div>
              </div>
              <div className="seperations">
                <div className="heading">8. Changes to This Privacy Policy</div>
                <div className="underTheHeading">
                  We may update this Privacy Policy from time to time to reflect
                  changes in our practices or for legal and regulatory reasons.
                  We will notify you of any material changes by posting the
                  revised policy on our website or app.
                </div>
              </div>
              <div className="seperations">
                <div className="heading">9. Contact Us: </div>
                <div className="underTheHeading">
                  If you have any questions or concerns regarding this Privacy
                  Policy, please contact us at info@passpapers.lk. By using Pass
                  Papers, you consent to the practices described in this Privacy
                  Policy. Please read this policy carefully and check for
                  updates periodically.
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default PrivacyPolicy;
