import React, { useState, useEffect } from "react";
import "./MySubscriptions.css";
import axios from 'axios';
import { Oval } from 'react-loader-spinner'
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import HOST_URL from './config';
import LoginStatus from './UserAuth';
import { useHistory } from 'react-router-dom';

const MySubscriptions = () => {

    const [Data, setData] = useState([])
    const [isLoading, setLoading] = useState(false);
    const [isButtonLoading, setButtonLoading] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const history = useHistory();

    // const [isLoggedIn, setIsLoggedIn] = useState(false);
    // const isLoggedInUserAuth = LoginStatus();

    // useEffect(() => {
    //     setIsLoggedIn(isLoggedInUserAuth)
    // }, [isLoggedInUserAuth]);


    // useEffect(() => {
    //     if (isLoggedIn === true) {
    //         history.push('/my-subscriptions');
    //         window.location.reload('/my-subscriptions')
    //     }
    //     else {

    //         history.push('/login');
    //         window.location.reload('/login')
    //     }
    // }, [])

    let date = new Date().toJSON()


    useEffect(() => {
        let isMounted = true;
        setLoading(true);
        if (isMounted) {
            getUserSubscriptions()
        }
        return () => {
            isMounted = false
        };
    }, [])

    async function getUserSubscriptions() {
        const token = localStorage.getItem('token');
        // API request
        await axios.get(`${HOST_URL}api/show-user-subscribed-list`, {
            headers: { 'Authorization': `Bearer ${token}` },
        }).then(response => {
            // let hh = response.data
            // console.log(hh)
            setData(response.data);
            setLoading(false);

        })
    }

    function handleExpiredSubscribeOnClick(subjectId) {
        setButtonLoading(true);
        const token = localStorage.getItem('token');

        axios.post(`${HOST_URL}api/create-order`, {
            subject_id: subjectId
        },
            {
                headers: { 'Authorization': `Bearer ${token}` },
            })
            .then(response => {
                // console.log(response)
                setButtonLoading(false); // Set button loading state back to false
                if (response.data == false) {
                    alert("Failed to do Payment")
                  }
                  else {
                    window.open(response.data)
                  }
                // console.log(response.data)
                // window.open(response.data)
            })
            .catch(error => {
                setButtonLoading(false); // Set button loading state back to false
                console.log(error);
                alert("Failed to do Payment")
            });
    }

    function handleUnSubscribeOnClick(subjectId) {
        setButtonLoading(true);
        const token = localStorage.getItem('token');
        axios.get(`${HOST_URL}api/unsubscribe-subject/` + subjectId, {
            headers: { 'Authorization': `Bearer ${token}` },
        })
            .then(response => {
                if (response.status == 200) {
                    setButtonLoading(false); // Set button loading state back to false
                    setAlertMessage({ type: "success", text: 'Unsubscribed Successfully!', color: 'green' });
                    setTimeout(() => {
                        setAlertMessage(null);
                    }, 5000);
                    getUserSubscriptions();
                }
                else {
                    setButtonLoading(false); // Set button loading state back to false
                    setAlertMessage({ type: "success", text: 'Failed to Unsubscribed!', color: 'red' });
                    setTimeout(() => {
                        setAlertMessage(null);
                    }, 5000);
                }
                // console.log(response)
                // setButtonLoading(false); // Set button loading state back to false
                // console.log(response.data)
                // window.open(response.data)
            })
            .catch(error => {
                setButtonLoading(false); // Set button loading state back to false
                setAlertMessage({ type: "success", text: 'Failed to Unsubscribed!', color: 'red' });
                setTimeout(() => {
                    setAlertMessage(null);
                }, 5000);
                console.log(error);
                // alert("Failed to Unsubscribe")
            });
    }
    // return (
    //     <div className="subscription-container">
    //         <div className="subscription-row">
    //             <div className="subscription-data-container">
    //                 Name
    //             </div>
    //             <div className="subscription-data-container">
    //                 Name
    //             </div>
    //         </div>
    //     </div>
    // )
    const subscriptions = [
        { id: 1, name: 'Subscription A', price: '$10' },
        { id: 2, name: 'Subscription B', price: '$15' },
        { id: 3, name: 'Subscription C', price: '$20' },
        { id: 4, name: 'Subscription D', price: '$25' },
        { id: 5, name: 'Subscription E', price: '$30' },
        { id: 6, name: 'Subscription F', price: '$35' },
        // Add more subscriptions as needed
    ];

    return (
        isLoading ?
            <div className="spinner" style={{
                marginTop: "250px",
                marginBottom: "250px",
                display: "flex",
                justifyContent: "center"
            }}>
                <Oval
                    height={40}
                    width={40}
                    color="#4fa94d"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                    ariaLabel='oval-loading'
                    secondaryColor="#4fa94d"
                    strokeWidth={2}
                    strokeWidthSecondary={2}
                />
            </div>
            :

            <div className="subscription-container">
                {
                    alertMessage && (
                        <div style={{ color: alertMessage.color }}>
                            {/* {alertMessage.text} */}
                            <Stack sx={{ width: '100%' }} spacing={2}>
                                <Alert severity={alertMessage.type}>{alertMessage.text}</Alert>
                            </Stack>
                        </div>
                    )

                }
                <h2>Your Subscriptions</h2>
                <div className="my-subscriptions">
                    {
                        Data.length == 0 ?
                            <div style={{
                                textAlign: "center",
                                marginBottom: "100px",
                                marginTop: "100px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}>
                                <h2 style={{
                                    color: "red"
                                }}>No Subscription</h2>
                            </div>
                            :
                            <div className="subscription-list">
                                {
                                    Data.map((subscription) => (
                                        <div key={subscription.id} className="subscription">
                                            <h3>{subscription.subject.subject}</h3>
                                            <p>Rs. {subscription.amount}</p>
                                            <h3>{subscription.status}</h3>
                                            <p>Expired At - {subscription.expire_date}</p>
                                            {date > subscription.expire_date ? (
                                                isButtonLoading ? (
                                                    <div className="spinner">
                                                        <Oval
                                                            height={30}
                                                            width={30}
                                                            color="#4fa94d"
                                                            wrapperStyle={{}}
                                                            wrapperClass=""
                                                            visible={true}
                                                            ariaLabel='oval-loading'
                                                            secondaryColor="#4fa94d"
                                                            strokeWidth={2}
                                                            strokeWidthSecondary={2}
                                                        />
                                                    </div>
                                                ) : (
                                                    <button onClick={() => handleExpiredSubscribeOnClick(subscription.subject_id)}>
                                                        Expired Click to Subscribe
                                                    </button>
                                                )
                                            ) : (

                                                isButtonLoading ? (
                                                    <div className="spinner">
                                                        <Oval
                                                            height={30}
                                                            width={30}
                                                            color="#4fa94d"
                                                            wrapperStyle={{}}
                                                            wrapperClass=""
                                                            visible={true}
                                                            ariaLabel='oval-loading'
                                                            secondaryColor="#4fa94d"
                                                            strokeWidth={2}
                                                            strokeWidthSecondary={2}
                                                        />
                                                    </div>
                                                ) : (
                                                    <button onClick={() => handleUnSubscribeOnClick(subscription.subject_id)} >Cancel Subscription</button>
                                                )
                                            )}
                                            {/* {

                                        date > subscription.expire_date ?

                                            <button onClick={() => handleExpiredSubscribeOnClick(subscription.subject_id)}>Expired Click to Subscribe</button> : <button>Cancel Subscription</button>
                                    } */}

                                        </div>
                                    ))
                                }

                            </div>
                    }
                </div>
            </div>
        // <div className="subscription-container">
        // <div className="my-subscriptions">
        //     <h2>Your Subscriptions</h2>
        //     <div className="subscription-list">
        //         {subscriptions.map((subscription) => (
        //             <div key={subscription.id} className="subscription">
        //                 <h3>{subscription.name}</h3>
        //                 <p>{subscription.price}</p>
        //                 <button>Cancel Subscription</button>
        //             </div>
        //         ))}
        //     </div>
        // </div>
        // </div>

    );
}
export default MySubscriptions;
