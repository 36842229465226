import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import './CardsWrap.css'
import axios from 'axios';
import HOST_URL from '../config';
import { Oval } from 'react-loader-spinner'


export default function PapersCardsWrap({ subject, medium_id }) {
    const [Data, setData] = useState([])
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        let isMounted = true;
        setLoading(true);

        if (isMounted) {
            getPapers(subject, medium_id)
        }
        // if(Array.isArray(data) && isMounted) setData(data)

        return () => {
            isMounted = false
        };
    }, [subject, medium_id])



    function getPapers(subject, medium_id) {
        // API request
        axios.get(`${HOST_URL}api/past-paper/show-papers/subject/` + subject + `/medium/` + medium_id + ``).then(response => {
            setData(response.data);
            setLoading(false);

        })
    }

    return (
        isLoading ?
            <div className="spinner" style={{
                marginTop: "250px",
                marginBottom: "250px",
                display: "flex",
                justifyContent: "center"
            }}>
                <Oval
                    height={40}
                    width={40}
                    color="#4fa94d"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                    ariaLabel='oval-loading'
                    secondaryColor="#4fa94d"
                    strokeWidth={2}
                    strokeWidthSecondary={2}
                />
            </div>
            :
            (Data.length === 0) ?
                // return (
                <div className="spinner" style={{
                    marginTop: "250px",
                    marginBottom: "250px",
                    display: "flex",
                    justifyContent: "center",
                    fontSize: "20px"
                }}>
                    No Papers
                </div>
                // )
                :
                <div className='cards-wrap'>
                    {
                        Data.map(item => {
                            return (
                                <div key={item.id} className="card">
                                    <Link
                                        to={{
                                            pathname: "/paper-data",
                                            state: { paper_id: item.id, paper_name: item.paper_name, image: item.image, year: item.year, medium: item.medium.medium }
                                        }}
                                        className="video-link">
                                        <img src={`${HOST_URL}storage/` + item.image} alt="Past Paper" className="image" />

                                        <div className="content">
                                            <div className="label-wrap">
                                                {/* <Link to="" className="video-link"><p className="label curved">Video</p></Link> */}
                                                {/* <img src="/images/video-circle.png" alt="Video Icon" className="video-icon" /> */}
                                            </div>
                                            <div className="sub-content">
                                                <p className="title">{item.paper_name} {item.year}</p>
                                                <div className="labels">
                                                    <div className='label-wrap'>
                                                        <p className="label light-blue">{item.parts.length + " Parts"}</p>
                                                    </div>
                                                    <div className='label-wrap'>
                                                        <p className="label royal-blue">{item.answer_sheets.length + " Answer sheets"}</p>
                                                    </div>
                                                    <div className="label-wrap">
                                                        {/* <Link to="" className="video-link"> <p className="label purple">Paper discussion</p></Link>
                                                <img src="/images/video-circle.png" alt="Video Icon" className="video-icon small" /> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            )
                        })
                    }
                </div>
    )
}