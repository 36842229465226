import React from 'react';
import './Footer.css';
import logo from '../logo_web.png';

import { Link } from 'react-router-dom';

function Footer() {
  return (
    <div className="footer-container">
      {/* <div className='footer-links'>
        <div className='footer-link-wrapper'>
          <div className='footer-link-items'>
            <h2>About Us</h2>
            <Link to='/sign-up'>How it works</Link>
            <Link to='/'>Testimonials</Link>
            <Link to='/'>Careers</Link>
            <Link to='/'>Investors</Link>
            <Link to='/'>Terms of Service</Link>
          </div>
          <div className='footer-link-items'>
            <h2>Contact Us</h2>
            <Link to='/'>Contact</Link>
            <Link to='/'>Support</Link>
            <Link to='/'>Destinations</Link>
            <Link to='/'>Sponsorships</Link>
          </div>
        </div>
        <div className='footer-link-wrapper'>
          <div className='footer-link-items'>
            <h2>Videos</h2>
            <Link to='/'>Submit Video</Link>
            <Link to='/'>Ambassadors</Link>
            <Link to='/'>Agency</Link>
            <Link to='/'>Influencer</Link>
          </div>
          <div className='footer-link-items'>
            <h2>Social Media</h2>
            <Link to='/'>Instagram</Link>
            <Link to='/'>Facebook</Link>
            <Link to='/'>Youtube</Link>
            <Link to='/'>Twitter</Link>
          </div>
        </div>
      </div> */}
      <section className="social-media">
        <div className="social-media-wrap">
          <div className="footer-logo">
            <Link to="/" className="social-logo">
              {/* Past Papers */}
              <img src={logo} alt="Logo" className="footer-logo-img" />
            </Link>
          </div>

          <div className="website-rights">PastPapers © 2022</div>
          <div className="website-rights">
            <a
              href='/policy'
              className='website-rights'
            >
              Privacy Policy
            </a>
          </div>
          <div className="social-icons">
            <a
              href="https://www.facebook.com/"
              target="_blank"
              className="social-icon-link facebook"
              aria-label="Facebook"
            >
              <i className="fab fa-facebook" />
            </a>

            <a
              href="https://www.instagram.com/"
              target="_blank"
              className="social-icon-link instagram"
              aria-label="Instagram"
            >
              <i className="fab fa-instagram" />
            </a>

            <a
              href="https://www.youtube.com/"
              target="_blank"
              className="social-icon-link youtube"
              aria-label="Youtube"
            >
              <i className="fab fa-youtube" />
            </a>

            <a
              href="https://www.linkedin.com/"
              target="_blank"
              className="social-icon-link linkedin"
              aria-label="Linkedin"
            >
              <i className="fab fa-linkedin" />
            </a>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Footer;
