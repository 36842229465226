import React from 'react'
import CardsWrap from '../CardsWrap/CardsWrap'
import './Alevel.css'

const Alevel = () => {

  const Data = [
    {
      title: 'Bio Science 2015',
      image: '/images/img-9.jpg'
    },
    {
      title: 'GIT 2015',
      image: '/images/img-9.jpg'
    },
    {
      title: 'Maths 2015',
      image: '/images/img-9.jpg'
    },
    {
      title: 'Physics 2015',
      image: '/images/img-9.jpg'
    },
    {
      title: 'Chemistry 2015',
      image: '/images/img-9.jpg'
    }
  ]

  return (
    <div className='container-AL'>
      <h1 className='alevel-text-h2'>Alevel Pastpapers Subjects</h1>

      <div className="container-fluid full-width">
        <div className="row-fluid">
          <a className="btn pull-left">All</a>
          <a className="btn">GIT</a>
          <a className="btn">Maths </a>
          <a className="btn">Bio</a>
          <a className="btn">Physics</a>
          <a className="btn">Chemistry</a>
        </div>

      </div>
      <CardsWrap data={Data} />
    </div>
  )
}

export default Alevel
